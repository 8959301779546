import "./membershipInformation.scss";
import { format, formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import {
  startOfDay,
  isAfter,
  isBefore,
  isSameDay,
  endOfDay,
  parseISO,
  differenceInWeeks,
  differenceInDays,
} from "date-fns";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { brandTypes } from "../../constants/commonConstant";
import getTypeIcon from "../../utils/getTypeIcon";
import Modal from "../modal/Modal";
import UpdateAmount from "./UpdateAmount";
import { isFutureDate } from "../../utils/getFutureDate.js";
import MakePaymentModal from "../modal/MakePaymentModal.jsx";
import MakePaymentAddMembershipModal from "../modal/MakePaymentAddMembershipModal.jsx";
import {
  getInfoModalHeaderText,
  getInfoModalParaText,
} from "../../utils/getInfoModalText";
import {
  UPDATE_MEMBERSHIP_AMOUNT,
  CANCEL_MEMBERSHIP,
  REVOKE_MEMBERSHIP_CANCELLATION,
  APPROVE_UNDER_18_MEMBER,
  UPFRONT_REVOKE_MEMBERSHIP,
  UPDATE_FITNESSPASSPORT,
  GET_STATUS_PAYMENT_UNDER_18,
  CHARGE_SINGLE_PAYMENT,
} from "../../gqloperations/mutations";
import {
  GET_MEMBER_BY_ID,
  LIST_MEMBER_CONTRACT,
  LIST_TRANSACTION_BY_MEMBERID,
} from "../../gqloperations/queries";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import CancelMembershipModal from "./CancelMembershipModal";
import StatusModal from "../modal/StatusModal";
import { useParams } from "react-router-dom";
import Table from "../table/Table";
import { useTableStateProps } from "../../hooks/useTableStateprops";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Button from "../formUiElements/Button";
import { getMembershipTypeAndAmount } from "../../utils/formatMembershipType";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { useMemberContext } from "../../contexts/MemberContext";
import actions from "../../assets/images/icons/action_menu.png";
import { Storage } from "aws-amplify";
import MakePaymentActivateModal from "../modal/makePaymentActivateModal.jsx";
import { sortAsc } from "../../utils/sort";
import FPbarcodeModal from "../modal/FPbarcodeModal.jsx";
import { addFrequencyToDate } from "../../utils/calculateTimePeriod.js";
import { useHandleError } from "../../hooks/useHandleError.js";

const MembershipInformation = ({ membershipAndBillingsForAContract }) => {
  const ref = useRef();
  const navigate = useNavigate();
  const { loggedUser, permissions } = useAuthContext();
  const handleError = useHandleError();
  const {
    createLog,
    getMemberById,
    member,
    getContracts,
    sidebarBrandId,
    sidebarLocationId,
  } = useMemberContext();
  const { id: memberId } = useParams();
  const [curModalDetails, setCurModalDetails] = useState({ name: "" });
  const [tableData, setTableData] = useState([]);
  const [showActions, setShowActions] = useState({});
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [showModalPaymentRevoke, setShowModalPaymentRevoke] = useState(false);
  const [paymentModalDetails, setPaymentModalDetails] = useState({});
  const [activateMembershipAmount, setActivateMembershipAmount] = useState("");
  const [revokeAmount, setRevokeAmount] = useState("");
  const [timezone, setTimezone] = useState(
    member?.getMember?.homeLocationDetails?.timezone ?? "Australia/Sydney"
  );
  const [voucherDetail, setVoucherDetail] = useState({});
  const [fitnessPassportNumber, setFitnessPassportNumber] = useState("");
  const [chargeSinglePayment, { loading: chargeSinglePaymentLoading }] =
    useMutation(CHARGE_SINGLE_PAYMENT);
  const {
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
  } = useTableStateProps();

  const [updateMembershipAmount] = useMutation(UPDATE_MEMBERSHIP_AMOUNT);
  const [cancelMembership, { loading: cancelMembershipLoading }] =
    useMutation(CANCEL_MEMBERSHIP);
  const [
    revokeMembershipCancellation,
    { loading: revokeMembershipCancellationLoading },
  ] = useMutation(REVOKE_MEMBERSHIP_CANCELLATION);
  const [
    upfrontRevokeMembershipCancellation,
    { loading: upfrontRevokeMembershipCancellationLoading },
  ] = useMutation(UPFRONT_REVOKE_MEMBERSHIP);
  const [approveUnderAgeMember, { loading: approveUnderAgeMemberLoading }] =
    useMutation(APPROVE_UNDER_18_MEMBER);

  const [updateFitnessPassport, { loading: updateFitnessPassportLoading }] =
    useMutation(UPDATE_FITNESSPASSPORT);

  const [getPaymentTypeUnder18, { loading: getPaymentTypeUnder18Loading }] =
    useMutation(GET_STATUS_PAYMENT_UNDER_18);

  useEffect(() => {
    if (membershipAndBillingsForAContract.length > 0) {
      setTableData(membershipAndBillingsForAContract);
      setTimezone(
        member?.getMember?.homeLocationDetails?.timezone ?? "Australia/Sydney"
      );
    } else {
      setTableData([]);
    }
  }, [membershipAndBillingsForAContract, member]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!ref?.current || ref.current.contains(e.target)) {
        return;
      }
      expandTable(false);
      setShowActions({});
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [ref]);

  const handleEditClick = ({ contractId, membershipAmount }) => {
    setCurModalDetails({
      name: "update-amount",
      contractId,
      membershipAmount,
    });
  };

  const handleCancelClick = (id, contractEndDateTime = null, contract) => {
    setCurModalDetails({
      name: "cancel-membership",
      contractId: id,
      contractEndDateTime,
      startDateTime: contract.startDateTime,
      contractLength: contract.contractLength,
      //is contract renew to know whether is it a contract power membership and is it renewed or not ?
      recurring: contract.recurring,
      //contract to know the last billing to decide the day of max cancellation
      contract: contract,
      isPending: contract.isPending,
    });
  };

  const handleRevokeClick = (id) => {
    setCurModalDetails({ name: "revoke-cancellation", contractId: id });
  };

  const handleInfoClick = ({
    contractId,
    suspensionStartDate,
    suspensionEndDate,
    isJuniorMemberPending,
    expiryDateTime,
    createdAt,
    suspensionDetails,
    signature,
  }) => {
    setCurModalDetails({
      name: "view-information",
      contractId,
      suspensionStartDate,
      suspensionEndDate,
      isJuniorMemberPending,
      expiryDateTime,
      createdAt,
      suspensionDetails,
      signature,
    });
  };

  const handleActivationClick = (id, isJuniorMemberPending, rowData) => {
    const isPaymentDelay =
      rowData?.paymentDelay?.amount !== 0 &&
      rowData?.paymentDelay?.paymentDelay !== null;
    const voucherDetail = rowData?.voucherDetail;
    const isVoucherDelay =
      voucherDetail?.paymentDelay?.amount !== 0 &&
      voucherDetail?.paymentDelay?.paymentDelay !== null;

    let isDisableMembershipAction;
    if (isPaymentDelay && rowData) {
      const params = {
        startDateTime: rowData?.startDateTime,
        amount: rowData?.paymentDelay?.amount,
        type: rowData?.paymentDelay?.type,
      };

      // startDateTime===null means juniorMember but how do we know that isDisableMembershipAction===true means always junior member?
      // Because if rowData?.startDateTime exists and isFutureDate(params) returns true, is this junior member?
      isDisableMembershipAction = rowData?.startDateTime
        ? isFutureDate(params)
        : true;
    }
    setActivateMembershipAmount(
      Object.keys(voucherDetail || {}).length
        ? isVoucherDelay
          ? voucherDetail?.joiningFee +
            voucherDetail?.activationFee +
            voucherDetail?.passFee
          : voucherDetail?.cost +
            voucherDetail?.joiningFee +
            voucherDetail?.activationFee +
            voucherDetail?.passFee
        : isDisableMembershipAction
        ? rowData?.joiningFee + rowData?.joiningFee2 + rowData?.joiningFee3
        : rowData?.costPrice +
          rowData?.joiningFee +
          rowData?.joiningFee2 +
          rowData?.joiningFee3
    );

    if (isJuniorMemberPending) {
      setCurModalDetails({ name: "activate-membership", contractId: id });
    }
  };

  const handleSuspension = (rowId, isEnabled) => {
    if (isEnabled) {
      navigate(`/dashboard/suspensions/${rowId}`);
    }
  };

  const handleUpgradeDowngrade = (rowId, isEnabled, fpMembership = null) => {
    if (fpMembership && isEnabled) {
      setCurModalDetails({ name: "fpMembership" });
    } else if (isEnabled) {
      navigate(`/dashboard/member/upgradedowngrade/${rowId}`);
    }
  };
  const activateMembershipAction = () => {
    toast.success("Membership activated successfully!");
    setCurModalDetails({ name: "" });
    getMemberById();
  };

  const revokeMembershipAction = () => {
    toast.success("Membership cancellation revoked");
    modalClose();
    getMemberById();
    getContracts();
  };

  const refetchQueriesRevoke = [
    {
      query: LIST_MEMBER_CONTRACT,
      variables: {
        id: `${curModalDetails?.contractId}`,
      },
    },
    {
      query: LIST_TRANSACTION_BY_MEMBERID,
      variables: { memberId: member?.getMember?.memberId },
    },
  ];
  const refetchQueries = [
    {
      query: GET_MEMBER_BY_ID,
      variables: {
        memberId: `${memberId}`,
      },
    },
  ];
  const handleActivateClick = async () => {
    await getPaymentTypeUnder18({
      variables: {
        memberId: member?.getMember?.memberId,
        operationName: "getStatusPaymentUnder18",
      },
      onCompleted: (data) => {
        const paymentType = data.getStatusPaymentUnder18.paymentType;
        if (paymentType === "CASH" || paymentType === "EFTPOS") {
          chargeSinglePayment({
            variables: {
              memberId: member?.getMember?.memberId,
              currentPaymentOption: paymentType,
              operation: "MAV",
              brandId: member?.getMember?.brandId,
              membershipActivationArguments: {
                isPaidInHub: true,
              },
              paymentInformationId:
                data.getStatusPaymentUnder18.futurePaymentInformationId,
              amount: activateMembershipAmount,
            },
            onCompleted: (data) => {
              getMemberById();
              setCurModalDetails({ name: "" });
              toast.success("Successfully activate membership");
            },
            onError: (error) => {
              setCurModalDetails({ name: "" });
              handleError(`${error}`);
            },
          });
        } else {
          activateMembershipAmount === 0
            ? approveUnderAgeMember({
                variables: {
                  memberId: member?.getMember?.memberId,
                  operationName: "approveUnder18Member",
                  memberContractId: curModalDetails.contractId,
                  ...(voucherDetail?.type === "COMPLIMENTARY" ||
                  voucherDetail?.type === "VISIT_PASS"
                    ? undefined
                    : {
                        paymentInformationId:
                          member?.getMember?.paymentDetails.items[0]?.id,
                      }),
                },
              })
                .then((res) => {
                  getMemberById();
                  setCurModalDetails({ name: "" });
                  toast.success("Successfully activate membership");
                })
                .catch((e) => {
                  handleError("Error: Cannot activate membership");
                  setCurModalDetails({ name: "" });
                })
            : setShowModalPayment(true);
        }
      },
      onError: (err) => {
        handleError(`${err}`);
      },
    });
  };
  const handleConfirmClick = () => {
    const cancellationDate = curModalDetails?.expiryDateTime;

    if (
      !(
        curModalDetails.recurring === true &&
        curModalDetails.contractLength !== null &&
        Number(paymentModalDetails.amount) !== 0
      )
    ) {
      cancelMembership({
        variables: {
          memberId,
          memberContractId: curModalDetails?.contractId,
          updatedBy: loggedUser?.getMember?.memberId,
          endDate: curModalDetails?.expiryDateTime,
          cancelImmediately: true,
        },
        onCompleted: () => {
          toast.success("Cancelled membership successfully!");
          modalClose();
          getMemberById();
          getContracts();
        },

        onError: (error) => {
          handleError("Error: Membership cannot be cancelled", error);
        },
      });
    } else {
      setPaymentModalDetails((prev) => {
        return {
          ...prev,

          name: "payment-modal",
        };
      });
      modalClose();
    }
  };

  const handleRevokeConfirm = () => {
    upfrontRevokeMembershipCancellation({
      variables: {
        memberId,
        memberContractId: curModalDetails?.contractId,
        operation: "UPFRONT",
      },
      onCompleted: (data) => {
        if (data?.revokeMembershipCancellation?.data?.amount) {
          setShowModalPaymentRevoke(true);
          setRevokeAmount(data.revokeMembershipCancellation.data.amount);
        } else {
          revokeMembershipCancellation({
            variables: {
              memberId,
              memberContractId: curModalDetails.contractId,
              operation: "REVOKE",
            },
            onCompleted: () => {
              toast.success("Revoked membership successfully!");
              modalClose();
              getMemberById();
              getContracts();
            },
            onError: (error) => {
              // handleError("Error: Membership cancel cannot be revoked", error);
              handleError(
                "Error: Membership cancel cannot be revoked: " + error.message,
                {
                  memberId,
                  memberContractId: curModalDetails?.contractId,
                  operation: "REVOKE",
                },
                true
              );
            },
            refetchQueries: [
              {
                query: LIST_MEMBER_CONTRACT,
                variables: {
                  id: `${curModalDetails?.contractId}`,
                },
              },
              {
                query: LIST_TRANSACTION_BY_MEMBERID,
                variables: { memberId: member?.getMember?.memberId },
              },
            ],
            // List_member_contract query needs to be refetched here LIST_MEMBER_CONTRACT
          });
        }
      },
      onError: (error) => {
        handleError(
          "Error: Membership cancel cannot be revoked: " + error.message,
          {
            memberId,
            memberContractId: curModalDetails?.contractId,
            operation: "UPFRONT",
          },
          true
        );
      },
    });
  };

  const modalClose = () => {
    setCurModalDetails({ name: "" });
    createLog(
      `Click CANCEL button for edit membership amount`,
      "member",
      `${member.getMember.memberId}`
    );
  };

  const handleCancelSubmit = (values, OnSubmitProps) => {
    const { dateOfCancellation } = values;

    if (curModalDetails.isPending) {
      cancelMembership({
        variables: {
          memberId,
          memberContractId: curModalDetails?.contractId,
          updatedBy: loggedUser?.getMember?.memberId,
          endDate: dateOfCancellation,
          cancelImmediately: true,
        },
        onCompleted: () => {
          toast.success("Cancelled membership successfully!");
          modalClose();
          getMemberById();
          getContracts();
        },

        onError: (error) => {
          // handleError("Error: Membership cannot be cancelled", error);
          handleError(
            "Error: Membership cannot be cancelled: " + error.message,
            {
              memberId,
              endDate: dateOfCancellation,
              memberContractId: curModalDetails.contractId,
            },
            true
          );
        },
      });
    } else if (
      curModalDetails.recurring &&
      curModalDetails.contractLength !== null
    ) {
      chargeSinglePayment({
        variables: {
          memberId,
          brandId: sidebarBrandId,
          operation: "CMS",
          futurePaymentOption: "CARD_ON_FILE_IS_DEFAULT",
          amount: 0,
          cancelMembershipArguments: {
            endDate: dateOfCancellation,
            option: "CALCULATE",
            memberContractId: curModalDetails.contractId,
          },
        },
        onCompleted: (res) => {
          const resAmount = res?.chargeSinglePaymentViaVivaPay?.message;
          const cancelData = JSON.parse(resAmount);
          if (Number(cancelData.amount)) {
            setPaymentModalDetails({
              amount: cancelData.amount,
              cancelMembershipArguments: {
                endDate: dateOfCancellation,
                option: "CHARGE",
                memberContractId: curModalDetails?.contractId,
              },
              weeksLeft: cancelData.remainingDays,
            });
            setCurModalDetails({
              ...curModalDetails,
              name: "cancel-confirm",
              expiryDateTime: dateOfCancellation,
            });
          } else {
            cancelMembership({
              variables: {
                memberId,
                memberContractId: curModalDetails?.contractId,
                updatedBy: loggedUser?.getMember?.memberId,
                endDate: dateOfCancellation,
                cancelImmediately: true,
              },
              onCompleted: () => {
                toast.success("Cancelled membership successfully!");
                modalClose();
                getMemberById();
                getContracts();
              },

              onError: (error) => {
                // handleError("Error: Membership cannot be cancelled", error);
                handleError(
                  "Error: Membership cannot be cancelled: " + error.message,
                  {
                    memberId,
                    endDate: dateOfCancellation,
                    memberContractId: curModalDetails.contractId,
                  },
                  true
                );
              },
            });
          }
        },
        onError: (error) => {
          handleError(`${error}`);
        },
      });
    } else {
      setCurModalDetails({
        ...curModalDetails,
        name: "cancel-confirm",
        expiryDateTime: dateOfCancellation,
      });
    }
  };

  const expandTable = (isExpand) => {
    const node = document.querySelector(".member-details-membership > .table");
    let height = node.offsetHeight;
    if (isExpand) {
      node.style.overflow = "hidden";
      height += 120;
    } else {
      node.style.overflow = "auto";
      height -= 72;
    }
    node.style.height = height + "px";
  };
  const handleDownloadAgreement = async (
    { juniorConsentForm, contractForm },
    isEnabled,
    isJuniorMembershipActivated
  ) => {
    if (isEnabled) {
      const downloadAgreement = async (url, fileName) => {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const urlObject = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = urlObject;
          link.download = fileName;
          link.target = "_blank";

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          window.URL.revokeObjectURL(urlObject);
        } catch (err) {
          handleError(`Error: ${err}`);
        }
      };

      try {
        if (isJuniorMembershipActivated) {
          const contractFormPdfLink = await Storage.get(contractForm);
          await downloadAgreement(contractFormPdfLink, "contract-form.pdf");
        } else {
          const juniorConsentFormPdfLink = await Storage.get(juniorConsentForm);
          await downloadAgreement(
            juniorConsentFormPdfLink,
            "junior-consent-form.pdf"
          );

          const contractFormPdfLink = await Storage.get(contractForm);
          await downloadAgreement(contractFormPdfLink, "contract-form.pdf");
        }
      } catch (err) {
        handleError(`Error: ${err}`);
      }
    }
  };

  const calcSuspension = (data) => {
    let nextSuspension;
    let upcomingSuspensions = [];
    let activeSuspensions = [];
    let activeSuspension;

    const validSuspensions = data?.suspensions?.items.filter(
      (curSuspension) => {
        const isCancelled = !!curSuspension?.cancelledDateTime;
        const suspensionEndTime = utcToZonedTime(
          curSuspension?.suspensionEndDateTime,
          timezone
        );

        const isFinished = isBefore(endOfDay(suspensionEndTime), new Date());

        const isValidMedical =
          curSuspension.suspensionType.toLowerCase() === "medical" &&
          (curSuspension.suspensionStatus === "APPROVED" ||
            curSuspension.suspensionStatus === "PENDING");

        const isStandardSuspension =
          curSuspension.suspensionType.toLowerCase() !== "medical";

        return (
          !isCancelled &&
          !isFinished &&
          (isValidMedical || isStandardSuspension)
        );
      }
    );

    if (validSuspensions.length > 0) {
      upcomingSuspensions = validSuspensions.filter((cur) => {
        const { suspensionType, suspensionStartDateTime } = cur;
        if (suspensionType.toLowerCase() === "medical") {
          return true;
        } else {
          const isStartDateAfterToday = isAfter(
            startOfDay(utcToZonedTime(suspensionStartDateTime, timezone)),
            new Date()
          );
          return isStartDateAfterToday;
        }
      });

      activeSuspensions = validSuspensions.filter((curActiveSus) => {
        const { suspensionStartDateTime, suspensionEndDateTime } = curActiveSus;
        const currentDate = new Date();
        const isApprovedSuspension =
          (curActiveSus.suspensionType.toLowerCase() === "medical" &&
            curActiveSus.suspensionStatus === "APPROVED") ||
          curActiveSus.suspensionType.toLowerCase() !== "medical";

        const isTodayMiddleOfSuspension =
          isBefore(
            startOfDay(utcToZonedTime(suspensionStartDateTime, timezone)),
            currentDate
          ) &&
          isAfter(
            startOfDay(utcToZonedTime(suspensionEndDateTime, timezone)),
            currentDate
          );

        const isTodayStartOfSuspension = isSameDay(
          utcToZonedTime(curActiveSus.suspensionStartDateTime, timezone),
          // new Date(curActiveSus.suspensionStartDateTime),
          currentDate
        );

        const isTodayEndOfSuspension = isSameDay(
          utcToZonedTime(curActiveSus.suspensionEndDateTime, timezone),
          currentDate
        );

        const isSuspensionActive =
          isApprovedSuspension &&
          (isTodayMiddleOfSuspension ||
            isTodayStartOfSuspension ||
            isTodayEndOfSuspension);

        return isSuspensionActive;
      });
    }

    if (activeSuspensions?.length === 1) {
      activeSuspension = activeSuspensions[0];
    }

    if (upcomingSuspensions?.length === 1) {
      nextSuspension = upcomingSuspensions[0];
    }

    return {
      upcomingSuspensions,
      nextSuspension,
      activeSuspension,
      // hasActiveSuspension,
    };
  };

  const calcCancellation = (data) => {
    let isCancellationAfterToday = false;
    let isCancellationToday = false;
    let isCancellationBeforeToday = false;
    const cancellationDate = data?.expiryDateTime ?? null;
    if (cancellationDate) {
      isCancellationToday = isSameDay(
        utcToZonedTime(new Date(cancellationDate), timezone),
        new Date()
      );
      // startOfDay(utcToZonedTime(new Date(startDateTime), timezone)), new Date();
      isCancellationBeforeToday = isBefore(
        startOfDay(utcToZonedTime(new Date(cancellationDate), timezone)),
        new Date()
      );
      isCancellationAfterToday = isAfter(
        startOfDay(utcToZonedTime(new Date(cancellationDate), timezone)),
        new Date()
      );
    }

    return {
      isCancellationBeforeToday,
      isCancellationToday,
      isCancellationAfterToday,
      cancellationDate,
    };
  };

  const calculateStatus = ({
    isPending,
    startDateTime,
    endDateTime,
    activeSuspension,
    isCancellationBeforeToday,
    isCancellationToday,
  }) => {
    if (isCancellationBeforeToday || isCancellationToday) return "CANCELLED";
    if (endDateTime) {
      const isExpired = isBefore(endOfDay(new Date(endDateTime)), new Date());
      if (isExpired) return "EXPIRED";
    }
    if (!!activeSuspension) return "SUSPENDED";

    if (isPending && !startDateTime) return "PENDING";
    if (
      startDateTime &&
      isAfter(
        startOfDay(utcToZonedTime(new Date(startDateTime), timezone)),
        new Date()
      )
    )
      return "FUTURE";

    return "ACTIVE";
  };

  const processMembershipDetails = (data) => {
    const { upcomingSuspensions, nextSuspension, activeSuspension } =
      calcSuspension(data);
    const hasActiveSuspension = !!activeSuspension;
    const {
      isCancellationBeforeToday,
      isCancellationToday,
      isCancellationAfterToday,
      cancellationDate,
    } = calcCancellation(data);

    let isPaymentDelay = false;
    let compVoucher = false;
    const { membershipName = "", membershipType = "" } =
      data?.membershipDetails || {};

    const isFPmembership =
      membershipType === "FITNESS_PASSPORT" ||
      membershipName
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "")
        .includes("fitnesspassport");
    isPaymentDelay =
      data?.paymentDelay?.amount !== 0 &&
      data?.paymentDelay?.paymentDelay !== null;

    const {
      isPending,
      startDateTime,
      juniorMemberconsentFormDetail,
      voucherDetail,
    } = data;
    const signature =
      (juniorMemberconsentFormDetail &&
        juniorMemberconsentFormDetail?.signature) ||
      null;

    if (voucherDetail?.type === "COMPLIMENTARY") compVoucher = true;
    const status = calculateStatus({
      ...data,
      activeSuspension,
      isCancellationBeforeToday,
      isCancellationToday,
    });

    const ddContractEndCancelled =
      !!data?.recurring &&
      !!data.endDateTime &&
      !!data?.expiryDateTime &&
      voucherDetail?.type !== "COMPLIMENTARY";

    const isPowerMembership =
      data?.recurring &&
      data.endDateTime === null &&
      data.contractLength !== null;

    const isPaidInFull = !data?.recurring;

    const isExpired = status === "EXPIRED";

    return {
      upcomingSuspensions,
      nextSuspension,
      activeSuspension,
      hasActiveSuspension,
      hasCancellationDate: !!cancellationDate,
      isCancellationBeforeToday,
      isCancellationAfterToday,
      isCancellationToday,
      status,
      isPending,
      startDateTime,
      signature,
      isPaymentDelay,
      data,
      isExpired,
      compVoucher,
      ddContractEndCancelled,
      isPaidInFull,
      isFPmembership,
      isPowerMembership,
    };
  };
  const cancelMembershipSuccess = () => {
    setPaymentModalDetails({});
    toast.success("Membership cancelled successfully");
    getMemberById();
    getContracts();
    setCurModalDetails({});
  };
  const cancelMembershipError = (error) => {
    handleError(`${error}`);
  };
  const handleSubmit = (values, OnSubmitProps) => {
    createLog(
      `Click Save button for edit membership amount`,
      "member",
      `${member.getMember.memberId}`
    );
    const { amount, reason, description } = values;
    // membership amount mutation

    updateMembershipAmount({
      variables: {
        memberContractId: curModalDetails.contractId,
        updatedBy: `${loggedUser.getMember.memberId}`,
        reason: reason,
        description: description,
        amountToUpdate: amount,
        type: "UPDATE_MEMBERSHIP_AMOUNT",
      },
      onCompleted: () => {
        toast.success("Updated successfully!");
        modalClose();
        getMemberById();
        getContracts();
      },

      onError: (error) => {
        handleError("Fail to update membership amount", error);
      },
    });
  };

  const handleFitnessPassportSubmit = (values, OnSubmitProps) => {
    const { fitnessPassportNum } = values;
    updateFitnessPassport({
      variables: {
        memberContractId: curModalDetails.contractId,
        FitnessPassportNumber: fitnessPassportNum,
      },
      onCompleted: (data) => {
        if (data?.updateFitnessPassport) {
          toast.success("Updated successfully!");
          modalClose();
          getMemberById();
          getContracts();
        } else {
          handleError(
            "Error: The fitness passport number is invalid.",
            {
              memberId,
              memberContractId: curModalDetails?.contractId,
              FitnessPassportNumber: fitnessPassportNum,
            },
            true
          );
          modalClose();
        }
      },
      onError: (error) => {
        handleError(
          "Error: Fitness passport error: " + error.message,
          {
            memberId,
            memberContractId: curModalDetails?.contractId,
            FitnessPassportNumber: fitnessPassportNum,
          },
          true
        );
      },
    });
  };
  const handleRenewClick = (contractId) => {
    navigate(`/dashboard/addMembershipToMember/${contractId}`);
  };
  const columns = [
    {
      // accessorKey: "membershipName",
      accessorFn: (row) => {
        return (
          <>
            {row.membershipName}
            <br />
            {row.startDateTime ? (
              differenceInDays(new Date(), new Date(row.startDateTime)) < 7 ? (
                <span
                  style={{
                    color: "var(--color-persianblue)",
                    fontSize: "10px",
                  }}
                >
                  (7-day cooling off period)
                </span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </>
        );
      },
      header: "Membership",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "membership",
    },
    {
      header: "Home Club",
      cell: member?.getMember?.homeLocationDetails?.name,
      enableSorting: false,
      enableGlobalFilter: false,
      id: "homeClub",
    },
    {
      accessorFn: (row) =>
        row.startDateTime
          ? format(
              utcToZonedTime(
                row.startDateTime,
                member?.getMember?.homeLocationDetails?.timezone
              ),
              "dd/MM/yy"
            )
          : "",
      header: "Start",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "start",
    },
    {
      accessorFn: (row) => {
        const sortedByDebitDate = sortAsc(row?.billings, "debitDate");

        const findNextDebitDate = sortedByDebitDate.find((cur) =>
          isAfter(startOfDay(new Date(cur.debitDate)), new Date())
        );
        return findNextDebitDate
          ? format(new Date(findNextDebitDate.debitDate), "dd/MM/yyyy")
          : "";
      },
      header: "Debit Date",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "startDate",
    },
    {
      accessorFn: (row) => {
        return row;
      },
      header: "Amount",
      cell: (info) =>
        info.getValue()?.costPrice
          ? getMembershipTypeAndAmount(info.getValue())
          : "",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "managerdetails",
    },
    {
      accessorFn: (row) => row?.billings[0]?.paymentType,
      header: "Payment Type",
      cell: (info) => info.getValue()?.replace("_", " "),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "payementType",
    },
    {
      accessorFn: (row) => {
        const { voucherDetail } = row;
        return (
          <>
            {Object.keys(voucherDetail || {}).length ? (
              <p className="text-green">Yes</p>
            ) : (
              <p className="text-red">No</p>
            )}
          </>
        );
      },
      header: "Voucher",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "voucherDetail",
    },
    {
      accessorFn: (row) => {
        const { status } = processMembershipDetails(row);
        return (
          <>
            {status === "ACTIVE" && <p className="text-green">{status}</p>}
            {(status === "CANCELLED" || status === "EXPIRED") && (
              <p className="text-red">{status}</p>
            )}
            {status === "SUSPENDED" && (
              <p style={{ color: "yellow" }}>{status}</p>
            )}
            {status === "PENDING" && (
              <p style={{ color: "yellow" }}>{status}</p>
            )}
            {status === "FUTURE" && <p style={{ color: "yellow" }}>{status}</p>}
          </>
        );
      },
      header: "Status",
      cell: (info) => {
        const status = info.getValue();

        const {
          isPending,
          startDateTime,
          hasActiveSuspension,
          upcomingSuspensions,
          nextSuspension,
          hasCancellationDate,
          isCancellationAfterToday,
          activeSuspension,
        } = processMembershipDetails(info.row.original);

        const isJuniorMemberPending = !!isPending && !startDateTime;

        return (
          <>
            <div className="action-icons-memberships">
              <span>{status}</span>

              {((hasCancellationDate && isCancellationAfterToday) ||
                (isJuniorMemberPending && !hasCancellationDate) ||
                hasActiveSuspension ||
                upcomingSuspensions?.length > 0 ||
                (hasActiveSuspension && upcomingSuspensions?.length > 0)) && (
                <AiOutlineInfoCircle
                  onClick={() => {
                    handleInfoClick({
                      contractId: info.row.original?.id,
                      suspensionStartDate:
                        nextSuspension?.suspensionEndDateTime?.length > 0
                          ? nextSuspension?.suspensionStartDateTime
                          : activeSuspension?.suspensionStartDateTime,
                      suspensionEndDate: nextSuspension?.suspensionEndDateTime
                        ? nextSuspension?.suspensionEndDateTime
                        : activeSuspension?.suspensionEndDateTime,
                      isJuniorMemberPending,
                      expiryDateTime: info?.row?.original?.expiryDateTime,
                      createdAt: info?.row?.original?.createdAt,
                      suspensionDetails: nextSuspension
                        ? nextSuspension
                        : activeSuspension,
                      signature:
                        info?.row?.original?.juniorMemberconsentFormDetail
                          ?.signature,
                    });

                    createLog(
                      `Clicked Information icon to view membership information for ${member.getMember.givenName} ${member.getMember.surname}`,
                      "member",
                      `${member.getMember.memberId}`
                    );
                  }}
                />
              )}
            </div>
          </>
        );
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "status",
    },
    {
      accessorFn: (row) => {
        if (row?.endDateTime && row?.expiryDateTime) {
          const endDateTime = parseISO(row.endDateTime);
          const expiryDateTime = parseISO(row.expiryDateTime);

          const earliestDate =
            endDateTime < expiryDateTime ? endDateTime : expiryDateTime;

          return formatInTimeZone(
            new Date(earliestDate),
            timezone,
            "dd/MM/yyyy"
          );
        } else if (row?.endDateTime) {
          return formatInTimeZone(
            new Date(row?.endDateTime),
            timezone,
            "dd/MM/yyyy"
          );
        } else if (row?.expiryDateTime) {
          return formatInTimeZone(
            new Date(row?.expiryDateTime),
            timezone,
            "dd/MM/yyyy"
          );
        } else {
          return "";
        }
      },
      header: "EXPIRY",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "pifExpiry",
    },
    {
      id: "row-actions",
      header: "ACTIONS",
      cell: (props) => {
        const { id: memberContractId, endDateTime: contractEndDateTime } =
          props?.row?.original;

        const areActionsVisible = showActions.hasOwnProperty(memberContractId);
        const { voucherDetail } = props?.row?.original;
        const { createdAt } = props?.row?.original;
        const {
          isPending,
          isPaymentDelay,
          startDateTime,
          hasActiveSuspension,
          hasCancellationDate,
          isCancellationBeforeToday,
          isCancellationToday,
          isCancellationAfterToday,
          upcomingSuspensions,
          activeSuspension,
          nextSuspension,
          signature,
          data,
          status,
          isExpired,
          compVoucher,
          ddContractEndCancelled,
          isPaidInFull,
          isFPmembership,
          isPowerMembership,
        } = processMembershipDetails(props.row.original);
        const locationOpenDate =
          props.row.original.joinedLocationDetail?.openDate;
        const hasMedicalSuspension = upcomingSuspensions.some((curSus) => {
          return (
            curSus?.suspensionType.toLowerCase() === "medical" &&
            (curSus?.suspensionStatus.toLowerCase() === "approved" ||
              curSus?.suspensionStatus.toLowerCase() === "pending")
          );
        });
        const isBlock = member?.getMember?.isBlocked;
        const isYouthCancelled = isPending && data.expiryDateTime;
        const isJuniorMemberPending =
          !!isPending && !startDateTime && signature;
        let isDisableMembershipAction;

        if (isPaymentDelay && data) {
          const params = {
            startDateTime: data?.startDateTime,
            amount: data?.paymentDelay?.amount,
            type: data?.paymentDelay?.type,
          };

          isDisableMembershipAction = data?.startDateTime
            ? isFutureDate(params)
            : true;
        }
        // const hasPermission = loggedUser.getMember.role.substring(1) > 2;

        const isMembershipPIF = !props?.row?.original?.recurring;

        const isCancelledInThePast =
          hasCancellationDate &&
          !isCancellationToday &&
          !isCancellationAfterToday;

        const isCancellationUpcoming =
          hasCancellationDate && isCancellationAfterToday;
        // cant cancel membership that already has an expiryDate
        const shouldCancelBtnWork =
          !hasActiveSuspension &&
          !hasMedicalSuspension &&
          !nextSuspension &&
          !isCancelledInThePast &&
          !isCancellationBeforeToday &&
          !isCancellationToday &&
          !isCancellationUpcoming &&
          !isExpired;

        // isCancellationBeforeToday || isCancellationToday
        const hasContractStarted = isBefore(
          startOfDay(new Date(props?.row?.original.startDateTime)),
          new Date()
        );
        const shouldUpgradeBtnShow =
          !isBlock &&
          !isMembershipPIF &&
          hasContractStarted &&
          !activeSuspension &&
          upcomingSuspensions.length === 0 &&
          !props?.row?.original.expiryDateTime &&
          !member.getMember?.outstandingBalance &&
          props?.row?.original.billings.length > 0 &&
          !isExpired &&
          (!locationOpenDate ||
            isBefore(startOfDay(new Date(locationOpenDate)), new Date()) ||
            isSameDay(new Date(locationOpenDate), new Date()));

        const shouldCancelBtnBeEnabled = shouldCancelBtnWork;

        const shouldRevokeBtnBeEnabled =
          !isPowerMembership &&
          !ddContractEndCancelled &&
          isCancellationAfterToday &&
          !hasActiveSuspension &&
          !hasMedicalSuspension &&
          !isYouthCancelled &&
          !isExpired;

        const shouldEditBtnBeEnabled =
          // !isDisableMembershipAction &&
          !isJuniorMemberPending &&
          permissions.includes("CanEditMembershipPayment") &&
          shouldCancelBtnWork &&
          !isExpired &&
          !compVoucher;

        const shouldDownloadAgreementBtnBeEnabled =
          !!props.row.original?.memberContractUrl;

        const shouldUpDowngradeBtnBeEnabled =
          !isDisableMembershipAction &&
          !isJuniorMemberPending &&
          memberContractId &&
          permissions.includes("CanSuspendMembership") &&
          shouldUpgradeBtnShow &&
          !compVoucher;
        const shouldSusBtnBeEnabled =
          !compVoucher &&
          !isDisableMembershipAction &&
          isBlock !== true &&
          // !isJuniorMemberPending
          !isPending &&
          memberContractId &&
          !hasCancellationDate &&
          permissions.includes("CanSuspendMembership") &&
          !isExpired &&
          (!locationOpenDate ||
            isBefore(startOfDay(new Date(locationOpenDate)), new Date()) ||
            isSameDay(new Date(locationOpenDate), new Date()));
        const shouldVoucherBtnBeEnabled =
          status !== "CANCELLED" && Object.keys(voucherDetail || {}).length;
        return (
          <div className="action-container">
            <img
              className="dropdown-btn"
              src={actions}
              onClick={(e) => {
                expandTable(true);
                e.stopPropagation();
                setShowActions({
                  [memberContractId]: true,
                });
              }}
              alt=""
            />
            {areActionsVisible && (
              <span className="action-options-menu " ref={ref}>
                <ul className="membership-options">
                  <li
                    onClick={(e) => {
                      e.stopPropagation();
                      setVoucherDetail(voucherDetail);
                      handleActivationClick(
                        memberContractId,
                        isJuniorMemberPending,
                        props?.row?.original
                      );
                      createLog(
                        `Clicked activate for ${member.getMember.givenName} ${member.getMember.surname}`,
                        "member",
                        `${member.getMember.memberId}`
                      );
                      if (isJuniorMemberPending && signature) {
                        setShowActions({});
                      }
                    }}
                  >
                    <Button
                      name="Activate"
                      btntype="submit"
                      btnname="submit"
                      className={`btn ${
                        isJuniorMemberPending
                          ? "btn-transparent btn-small"
                          : "disabled"
                      } `}
                    />
                  </li>
                  {permissions.includes("CanCancelMembershipForMember") && (
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        if (shouldCancelBtnBeEnabled) {
                          handleCancelClick(
                            memberContractId,
                            contractEndDateTime,
                            props?.row?.original
                          );
                          createLog(
                            `Clicked cancel membership for member button ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
                            "member",
                            `${member?.getMember?.memberId}`
                          );
                          setShowActions({});
                        }
                      }}
                    >
                      <Button
                        name="Cancel Membership"
                        btntype="submit"
                        btnname="submit"
                        className={`btn ${
                          shouldCancelBtnBeEnabled
                            ? "btn-transparent btn-small"
                            : "disabled"
                        } `}
                      />
                    </li>
                  )}
                  {permissions.includes("CanRevokeMembershipCancellation") && (
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        if (shouldRevokeBtnBeEnabled) {
                          handleRevokeClick(memberContractId);
                          setShowActions({});
                        }
                      }}
                    >
                      <Button
                        name="Revoke cancellation"
                        btntype="submit"
                        btnname="submit"
                        className={`btn ${
                          shouldRevokeBtnBeEnabled
                            ? "btn-transparent btn-small"
                            : "disabled"
                        } `}
                      />
                    </li>
                  )}
                  {permissions.includes("CanEditMembershipAmountToMember") && (
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        if (shouldEditBtnBeEnabled) {
                          handleEditClick({
                            membershipAmount: props.row.original.costPrice,
                            contractId: memberContractId,
                          });

                          createLog(
                            `Clicked Edit icon to edit membership amount for ${member.getMember.givenName} ${member.getMember.surname}`,
                            "member",
                            `${member.getMember.memberId}`
                          );
                          setShowActions({});
                        }
                      }}
                    >
                      <Button
                        name="Edit Membership Amount"
                        btntype="submit"
                        btnname="submit"
                        className={`btn ${
                          shouldEditBtnBeEnabled
                            ? "btn-transparent btn-small"
                            : "disabled"
                        } `}
                      />
                    </li>
                  )}

                  <li
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!shouldDownloadAgreementBtnBeEnabled) {
                        setShowActions({});
                      }
                      const isMembershipActivated =
                        !props?.row?.original?.isPending &&
                        !!props?.row?.original?.startDateTime;

                      const memberContractUrl =
                        props.row.original?.juniorMemberconsentFormDetail
                          ?.formUrl &&
                        props.row.original?.juniorMemberconsentFormDetail
                          ?.formUrl !== ""
                          ? {
                              juniorConsentForm:
                                props.row.original?.juniorMemberconsentFormDetail?.formUrl.slice(
                                  7
                                ),
                              contractForm:
                                props.row.original?.memberContractUrl.slice(7),
                            }
                          : {
                              contractForm:
                                props.row.original?.memberContractUrl.slice(7),
                            };
                      handleDownloadAgreement(
                        memberContractUrl,
                        shouldDownloadAgreementBtnBeEnabled,
                        isMembershipActivated
                      );
                    }}
                  >
                    <Button
                      name="Download Agreement"
                      btntype="submit"
                      btnname="submit"
                      className={`btn ${
                        shouldDownloadAgreementBtnBeEnabled
                          ? "btn-transparent btn-small"
                          : "disabled"
                      } `}
                    />
                  </li>

                  <li
                    onClick={(e) => {
                      e.stopPropagation();

                      handleUpgradeDowngrade(
                        memberContractId,
                        shouldUpDowngradeBtnBeEnabled,
                        isFPmembership
                      );
                      setShowActions({});
                    }}
                  >
                    <Button
                      name="Upgrade/downgrade"
                      btntype="submit"
                      btnname="submit"
                      className={`btn ${
                        (shouldUpDowngradeBtnBeEnabled && isFPmembership) ||
                        shouldUpDowngradeBtnBeEnabled
                          ? "btn-transparent btn-small"
                          : "disabled"
                      } `}
                    />
                  </li>

                  <li
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSuspension(memberContractId, shouldSusBtnBeEnabled);
                    }}
                  >
                    <Button
                      name="Suspension"
                      btntype="submit"
                      btnname="submit"
                      className={`btn ${
                        shouldSusBtnBeEnabled
                          ? "btn-transparent btn-small"
                          : "disabled"
                      } `}
                    />
                  </li>
                  <li
                    onClick={(e) => {
                      e.stopPropagation();
                      if (shouldVoucherBtnBeEnabled) {
                        setCurModalDetails({
                          name: "view-voucher",
                          voucherDetail: voucherDetail,
                          createdAt: createdAt,
                        });
                        setShowActions({});
                      }
                    }}
                  >
                    <Button
                      name="Voucher"
                      btntype="submit"
                      btnname="submit"
                      className={`btn ${
                        shouldVoucherBtnBeEnabled
                          ? "btn-transparent btn-small"
                          : "disabled"
                      } `}
                    />
                  </li>
                  <li
                    onClick={() => {
                      if (isFPmembership) {
                        setCurModalDetails({
                          name: "fp-barcode",
                          contractId: memberContractId,
                        });
                        setFitnessPassportNumber(
                          props.row.original.fitnessPassportNumber
                        );
                        setShowActions({});
                      }
                    }}
                  >
                    <Button
                      name="FP barcode"
                      btntype="submit"
                      btnname="submit"
                      className={`uppercase ${
                        isFPmembership
                          ? "btn-transparent btn-small"
                          : "disabled"
                      }`}
                    />
                  </li>
                  <li
                    onClick={() => {
                      if (isPaidInFull && status !== "FUTURE") {
                        handleRenewClick(memberContractId);
                        setShowActions({});
                      }
                    }}
                  >
                    <Button
                      name="Renew"
                      btntype="submit"
                      btnname="submit"
                      className={`btn ${
                        isPaidInFull && status !== "FUTURE"
                          ? "btn-transparent btn-small"
                          : "disabled"
                      } `}
                    />
                  </li>
                </ul>
              </span>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div className="member-details-membership">
      {curModalDetails?.name && (
        <Modal>
          {curModalDetails?.name === "update-amount" && (
            <UpdateAmount
              cancelFn={modalClose}
              headerText={"Edit Membership Amount"}
              initialAmount={curModalDetails.membershipAmount}
              handleSubmit={handleSubmit}
            />
          )}
          {curModalDetails?.name === "cancel-membership" && (
            <CancelMembershipModal
              cancelFn={modalClose}
              headerText={"Cancel Membership"}
              initialAmount={curModalDetails.membershipAmount}
              handleSubmit={handleCancelSubmit}
              loading={chargeSinglePaymentLoading}
              timezone={timezone}
              contract={curModalDetails?.contract}
            />
          )}
          {curModalDetails?.name === "cancel-confirm" && (
            <StatusModal
              modalHeaderTxt={"Cancel Membership"}
              modalParaText={
                curModalDetails.contractLength !== null &&
                curModalDetails.recurring
                  ? `This membership has ${paymentModalDetails.weeksLeft} days left on this contract, owing $${paymentModalDetails?.amount}. 
                Are you sure you want to proceed with the cancellation of this membership?`
                  : "Are you sure you want to proceed with the cancellation of this membership?"
              }
              handleStatusClick={handleConfirmClick}
              modalClose={modalClose}
              loading={cancelMembershipLoading}
            />
          )}
          {curModalDetails?.name === "revoke-cancellation" && (
            <StatusModal
              modalHeaderTxt={"Revoke Membership Cancellation"}
              modalParaText={
                "Are you sure you want to revoke the cancellation of this membership?"
              }
              handleStatusClick={handleRevokeConfirm}
              modalClose={modalClose}
              loading={
                revokeMembershipCancellationLoading ||
                upfrontRevokeMembershipCancellationLoading
              }
            />
          )}
          {curModalDetails?.name === "activate-membership" && (
            <StatusModal
              loading={
                approveUnderAgeMemberLoading ||
                chargeSinglePaymentLoading ||
                getPaymentTypeUnder18Loading
              }
              modalHeaderTxt={"Activate Membership"}
              modalParaText={
                "Are you sure you want to activate this membership?"
              }
              handleStatusClick={handleActivateClick}
              modalClose={modalClose}
            />
          )}
          {curModalDetails?.name === "view-information" && (
            <StatusModal
              showOneBtn={true}
              modalHeaderTxt={getInfoModalHeaderText(
                curModalDetails,
                member?.getMember?.homeLocationDetails?.timezone ??
                  "Australia/Sydney"
              )}
              modalParaText={getInfoModalParaText(
                curModalDetails,
                member?.getMember?.homeLocationDetails?.timezone ??
                  "Australia/Sydney"
              )}
              handleStatusClick={handleConfirmClick}
              modalClose={modalClose}
            />
          )}
          {curModalDetails?.name === "fpMembership" && (
            <StatusModal
              showOneBtn={true}
              modalHeaderTxt="Fitness Passport Membership"
              modalParaText="Fitness Passport Membership cannot be upgraded / downgraded"
              modalClose={modalClose}
            />
          )}
          {curModalDetails?.name === "view-voucher" && (
            <StatusModal
              modalHeaderTxt={"Voucher Details"}
              modalParaText={`Voucher code ${
                curModalDetails?.voucherDetail?.voucherCode
              } applied on membership ${
                curModalDetails?.voucherDetail?.membershipDetail?.membershipName
              } on ${
                curModalDetails?.createdAt
                  ? format(new Date(curModalDetails?.createdAt), "dd/MM/yyyy")
                  : ""
              }`}
              showOneBtn
              modalClose={modalClose}
            />
          )}
          {curModalDetails?.name === "fp-barcode" && (
            <FPbarcodeModal
              cancelFn={modalClose}
              handleFitnessPassportSubmit={handleFitnessPassportSubmit}
              fitnessPassportNum={fitnessPassportNumber}
            />
          )}
        </Modal>
      )}
      {showModalPayment && (
        <MakePaymentAddMembershipModal
          amount={activateMembershipAmount}
          memberDetail={member?.getMember}
          membershipType="weekly"
          operation="MAV"
          setShowModalPayment={setShowModalPayment}
          locationId={sidebarLocationId}
          onApiCompleted={() => activateMembershipAction()}
          onApiError={(e) => handleError(`${e}`)}
          apiRefetchQueries={refetchQueries}
        />
      )}
      {showModalPaymentRevoke && (
        <MakePaymentActivateModal
          amount={(revokeAmount * 1).toFixed(2)}
          setShowModalPayment={setShowModalPaymentRevoke}
          operation="RMC"
          onApiCompleted={() => revokeMembershipAction()}
          onApiError={(e) => {
            handleError("Error: Membership cancel cannot be revoked");
          }}
          apiRefetchQueries={refetchQueriesRevoke}
          memberDetail={member?.getMember}
        />
      )}
      {paymentModalDetails.name === "payment-modal" && (
        <MakePaymentModal
          amount={paymentModalDetails.amount}
          setShowModalPayment={setPaymentModalDetails}
          operation="CMS"
          locationId={sidebarLocationId}
          payloadData={paymentModalDetails?.cancelMembershipArguments}
          onApiCompleted={() => cancelMembershipSuccess()}
          onApiError={(e) => cancelMembershipError(e)}
        />
      )}
      <Table
        data={tableData}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        sorting={sorting}
        setSorting={setSorting}
        columns={columns}
        rowsPerPage={10}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
      />
    </div>
  );
};
export default MembershipInformation;
