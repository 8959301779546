import "./debtCollection.scss";
import { useMemberContext } from "../../../../contexts/MemberContext";
import {
  Table,
  Checkbox,
  Button,
  GenerateBox,
  StatusModal,
  Modal,
} from "../../../../components";

import { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { DEBT_COLLECTION_ACTIONS } from "../../../../gqloperations/mutations";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { sortDesc } from "../../../../utils/sort";
import { BeatLoader } from "react-spinners";
import actions from "../../../../assets/images/icons/action_menu.png";
import { useHandleError } from '../../../../hooks/useHandleError';

const DebtCollection = ({ state }) => {
  const { id: locationId } = useParams();
  const [tab, setTab] = useState("overdue");
  const { setSorting, sorting } = useState([]);
  const ref = useRef();
  const [showActions, setShowActions] = useState({});
  const [deptCollectionActions, { loading: deptCollectionActionsLoading }] =
    useMutation(DEBT_COLLECTION_ACTIONS);
  const { sidebarBrandId } = useMemberContext();
  const [overdueTableData, setOverdueTableData] = useState([]);
  const [reportTableData, setReportTableData] = useState([]);
  const [checkedRows, setCheckedRows] = useState({ isAllRowsChecked: false });
  const [rowSelection, setRowSelection] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [listOverdueIdUnchecked, setListOverdueIdUnchecked] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [generate, setGenerate] = useState(false);
  const [curRow, setCurRow] = useState({});
  const [componentName, setComponentName] = useState("");
  const [sentLoading, setSentLoading] = useState(false);
  const handleError = useHandleError();

  const handleDownloadExcel = async (url, fileName) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = urlObject;
      link.download = fileName;
      link.target = "_blank";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(urlObject);
    } catch (err) {
      handleError("Error on download excel file try again later");
    }
  };

  const getListOverdueMembers = async () => {
    await deptCollectionActions({
      fetchPolicy: "no-cache",
      variables: {
        input: {
          action: "READ_ALL_DEBT",
          brandId: sidebarBrandId,
          locationId: locationId,
        },
      },
      onCompleted: (data) => {
        setListOverdueIdUnchecked([]);
        const checkedRowsObj = [
          ...JSON.parse(data.debtActions.body)?.data,
        ]?.reduce((acc, cur) => {
          if (cur.isApproved) {
            acc[cur.id] = true;
          }
          return acc;
        }, {});

        const numOfRows =
          [...JSON.parse(data.debtActions.body)?.data]?.length ?? 0;

        const numOfCheckedRows = Object.keys(checkedRowsObj)?.length ?? 0;
        const isAllRowsChecked = numOfCheckedRows === numOfRows;
        checkedRowsObj.isAllRowsChecked = isAllRowsChecked;
        setCheckedRows(checkedRowsObj);
        const sortDate = sortDesc(
          JSON.parse(data.debtActions.body)?.data,
          "createdAt"
        );

        setOverdueTableData(sortDate);
      },
      onError: (err) => {
        handleError(`${err}`);
      },
    });
  };

  const getListReports = async () => {
    await deptCollectionActions({
      fetchPolicy: "no-cache",
      variables: {
        input: {
          action: "READ_ALL_DEBT_REPORT",
          brandId: sidebarBrandId,
          locationId: locationId,
          startDate: startDate,
          endDate: endDate,
        },
      },
      onCompleted: (data) => {
        let tableData = JSON.parse(data.debtActions.body)?.data;
        if (state.location?.getLocation?.debtCollectionType === "ARMA") {
          const filterSent = JSON.parse(data.debtActions.body)?.data.filter(
            (item) => item.isSent
          );
          tableData = filterSent;
        }
        const sortSentreport = tableData.sort((a, b) => {
          if (!a.exportedDate) return -1;
          if (!b.exportedDate) return 1;
          return 0;
        });
        setReportTableData(sortDesc(sortSentreport, "exportedDate"));
      },
      onError: (err) => {
        handleError(`${err}`);
      },
    });
  };

  useEffect(() => {
    if (state.index === 9) {
      if (tab === "overdue") {
        setReportTableData([]);
        getListOverdueMembers();
      } else {
        if (generate) {
          getListReports();
        }
      }
    }
  }, [tab, generate, state]);

  useEffect(() => {
    setGenerate(false);
  }, [endDate, startDate]);

  const handleSave = () => {
    setSaveLoading(true);

    const groupId = overdueTableData.filter((item) => item.isApproved)[0]
      ?.groupId;
    const selectedRow = Object.keys(rowSelection);

    const selectedOverdueId = selectedRow.map(
      (curIndex) => overdueTableData[curIndex].id
    );

    deptCollectionActions({
      fetchPolicy: "no-cache",
      variables: {
        input: {
          action: "MASK_APPROVING",
          brandId: sidebarBrandId,
          locationId: locationId,
          debtSelectedIds: selectedOverdueId,
          debtUnselectedIds: listOverdueIdUnchecked,
          groupId: groupId ? groupId : null,
        },
      },
      onCompleted: (data) => {
        setSaveLoading(false);
        setRowSelection({});
        toast.success("Save successfully");
        getListOverdueMembers();
      },
      onError: (err) => {
        setSaveLoading(false);
        handleError(`${err}`);
      },
    });
  };

  const handleSentToDebtCollection = () => {
    setSentLoading(true);

    deptCollectionActions({
      fetchPolicy: "no-cache",
      variables: {
        input: {
          action: "SEND_TO_DEBT_COLLECTION",
          brandId: sidebarBrandId,
          locationId: locationId,
          groupId: curRow.groupId,
        },
      },
      onCompleted: (data) => {
        setSentLoading(false);
        setRowSelection({});
        setComponentName("");
        toast.success("Sent to debt collection successfully");
        getListReports();
      },
      onError: (err) => {
        setSentLoading(false);
        handleError(`${err}`);
      },
    });
  };
  const handleToggle = (row) => {
    if (row && typeof row.getToggleSelectedHandler === "function") {
      setCheckedRows((prevCheckedRows) => {
        const newCheckedRows = { ...prevCheckedRows };
        if (newCheckedRows.hasOwnProperty(row.original.id)) {
          delete newCheckedRows[row.original.id];

          setListOverdueIdUnchecked([
            ...listOverdueIdUnchecked,
            row.original.id,
          ]);
        } else {
          newCheckedRows[row.original.id] = true;
        }
        return newCheckedRows;
      });
      row?.getToggleSelectedHandler();
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!ref?.current || ref.current.contains(e.target)) {
        return;
      }
      setShowActions({});
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [ref]);

  const handleComponent = (componentName, rowObj) => {
    setComponentName(componentName);
    setCurRow(rowObj);
  };

  const modalClose = () => {
    setComponentName("");
    setCurRow({});
  };

  const OverdueMembercolumns = [
    {
      id: "check-box",
      header: ({ table }) => {
        return (
          <div className="head-checkbox-container">
            <div className="px-1">
              {checkedRows?.isAllRowsChecked ? (
                <Checkbox type="checkbox" />
              ) : (
                <Checkbox
                  {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                  }}
                />
              )}
            </div>
            <div>SELECT ALL</div>
          </div>
        );
      },
      cell: ({ row }) => (
        <div className="px-1">
          {checkedRows?.hasOwnProperty(row.original.id) ? (
            <Checkbox
              checked={
                checkedRows?.hasOwnProperty(row.original.id) ||
                row.getIsSelected()
              }
              indeterminate={row.getIsSomeSelected()}
              onChange={() => handleToggle(row)}
            />
          ) : (
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          )}
        </div>
      ),
    },
    {
      id: "memberName",
      header: "Member Name",
      enableSorting: true,
      enableGlobalFilter: true,
      cell: (info) => info.getValue(),
      accessorFn: (record) =>
        `${record?.debtorLastName} ${record?.debtorFirstName}`,
    },
    {
      id: "originalAmount",
      accessorFn: (record) => `$${record.originalAmount.toFixed(2)}`,
      header: "Overdue Amount",
      enableSorting: true,
      enableGlobalFilter: true,
      cell: (info) => info.getValue(),
    },
    {
      id: "netAmount",
      accessorFn: (record) => `$${record.netAmount.toFixed(2)}`,
      header: "Total fee amount",
      enableSorting: true,
      enableGlobalFilter: true,
      cell: (info) => info.getValue(),
    },
  ];
  const reportColumns = [
    {
      id: "totalMembers",
      accessorFn: (record) => `${record.totalMembers}`,
      header: "Total Members",
      cell: (info) => info.getValue(),
    },
    {
      id: "Total Overdue Amount",
      accessorFn: (record) => `$${record.totalOverdueAmount?.toFixed(2)}`,
      header: "Total Overdue Amount",
      cell: (info) => info.getValue(),
    },
    {
      id: "Total Fee Amount",
      accessorFn: (record) => `$${record.totalNetAmount?.toFixed(2)}`,
      header: "Total Fee Amount",
      cell: (info) => info.getValue(),
    },
    {
      id: "isSent",
      accessorFn: (record) => {
        return record.isSent;
      },
      header: "Sent",
      cell: (info) => {
        if (info.getValue()) {
          return <div className="text-green">Yes</div>;
        } else {
          return <div className="text-red">No</div>;
        }
      },
    },
    {
      id: "row-actions5",
      header: "ACTIONS",
      cell: (props) => {
        const { groupId } = props?.row?.original;

        const areActionsVisible = showActions.hasOwnProperty(groupId);
        return (
          <div className="action-container">
            <img
              className="dropdown-btn"
              src={actions}
              onClick={(e) => {
                e.stopPropagation();
                setShowActions((prevShowActions) => ({
                  ...prevShowActions,
                  [groupId]: !areActionsVisible,
                }));
              }}
              alt=""
            />
            {areActionsVisible && (
              <span className="action-options-menu" ref={ref}>
                <ul className="membership-options">
                  <li
                    onClick={() => {
                      handleDownloadExcel(
                        props.row.original.reportUrlS3,
                        "Report.csv"
                      );
                    }}
                  >
                    <Button
                      name="Download Excel"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li
                    onClick={() => {
                      if (!props.row.original.isSent) {
                        setComponentName("sent-debt", props.row.original);
                      }
                    }}
                  >
                    <Button
                      name="File sent to debt collection"
                      btntype="submit"
                      btnname="submit"
                      className={`btn btn-transparent btn-small ${
                        props.row.original.isSent ? "btn-disabled" : ""
                      }`}
                    />
                  </li>
                </ul>
              </span>
            )}
          </div>
        );
      },
    },
  ];
  const sentColumns = [
    {
      id: "exportedDate",
      header: "Date & Time",
      accessorFn: (record) =>
        `${format(new Date(record.exportedDate), "d/LL/yyyy k:mm:ss")}`,
      cell: (info) => info.getValue(),
      enableGlobalFilter: true,
      enableSorting: true,
    },
    {
      id: "row-actions5",
      header: "ACTIONS",
      className: "custom-actions-column",
      cell: (props) => {
        const { groupId } = props?.row?.original;
        const areActionsVisible = showActions.hasOwnProperty(groupId);
        return (
          <div className="action-container action-option-right">
            <img
              className="dropdown-btn"
              src={actions}
              onClick={(e) => {
                e.stopPropagation();
                setShowActions((prevShowActions) => ({
                  ...prevShowActions,
                  [groupId]: !areActionsVisible,
                }));
              }}
              alt=""
            />
            {areActionsVisible && (
              <span className="action-options-menu " ref={ref}>
                <ul className="membership-options">
                  <li
                    onClick={() => {
                      handleDownloadExcel(
                        props.row.original.reportLocationUrlS3,
                        "Report.csv"
                      );
                    }}
                  >
                    <Button
                      name="Download Excel"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                </ul>
              </span>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {componentName && (
        <Modal>
          {componentName === "sent-debt" && (
            <StatusModal
              curRow={curRow}
              modalHeaderTxt={"Sent to debt collection"}
              modalParaText={"HAVE YOU SENT THIS FILE TO DEBT COLLECTION?"}
              handleStatusClick={handleSentToDebtCollection}
              modalClose={modalClose}
              loading={sentLoading}
            />
          )}
        </Modal>
      )}
      <div className="debt-collection-container">
        <div className="tabs">
          <div className="tabs-list">
            <div
              onClick={() => setTab("overdue")}
              className={
                tab === "report"
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
            >
              Overdue Members
            </div>

            <div
              onClick={() => setTab("report")}
              className={
                tab === "overdue"
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
            >
              {state.location?.getLocation?.debtCollectionType === "ARMA"
                ? "SENT"
                : "REPORT"}
            </div>
          </div>

          {deptCollectionActionsLoading && !saveLoading && tab !== "report" ? (
            <div className="report">
              <BeatLoader className="loading" color="white" size={20} />
            </div>
          ) : (
            <div>
              <div className="tab-content" hidden={tab === "report"}>
                <Table
                  data={overdueTableData}
                  columns={OverdueMembercolumns}
                  setSorting={setSorting}
                  sorting={sorting}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  isBtnDisabled={
                    checkedRows.isAllRowsChecked ||
                    Object.keys(rowSelection).length === 0
                  }
                />
                {overdueTableData.length > 0 && (
                  <div className="button-container">
                    <Button
                      name={"Save"}
                      btntype="button"
                      btnname="save-button"
                      className="btn btn-confirm btn-large fs-12"
                      click={handleSave}
                      loading={saveLoading}
                    />
                  </div>
                )}
              </div>

              <div className="tab-content" hidden={tab === "overdue"}>
                <GenerateBox
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  setGenerate={setGenerate}
                  type="debtCollection"
                />
                {deptCollectionActionsLoading && !sentLoading ? (
                  <div className="report">
                    <BeatLoader className="loading" color="white" size={20} />
                  </div>
                ) : (
                  <Table
                    data={reportTableData}
                    columns={
                      state.location?.getLocation?.debtCollectionType === "ARMA"
                        ? sentColumns
                        : reportColumns
                    }
                    setSorting={setSorting}
                    sorting={sorting}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DebtCollection;
