import "./addMembershipToMember.scss";
import React, { useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import {
  differenceInYears,
  format,
  addMonths,
  startOfDay,
  endOfDay,
  parseISO,
  add,
} from "date-fns";
import {
  GET_ALL_LOCATIONS_BY_LOCATIONID,
  GET_MEMBERSHIP_TYPE_BY_ID,
  GET_MEMBER_BY_ID,
  healthQuestionsByBrandId,
  GET_LOCTAION_BY_ID,
} from "../../gqloperations/queries";
import {
  CHARGE_SINGLE_PAYMENT,
  RENEW_MEMBERSHIP,
} from "../../gqloperations/mutations";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useMemberContext } from "../../contexts/MemberContext";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import isAfter from "date-fns/isAfter";
import isSameDay from "date-fns/isSameDay";
import isBefore from "date-fns/isBefore";
import { useHandleError } from "../../hooks/useHandleError";
import {
  Modal,
  StatusModal,
  Header,
  Input,
  Button,
  Select,
  InputWithoutFormik,
  MakePaymentModal,
} from "../../components";
import Member from "../member/Member";
import MembershipList from "./addmembership/MembershipList";
import HealthQuestionaires from "./addmembership/HealthQuestionaires";
import Payment from "./addmembership/Payment";
import YourMembership from "./addmembership/YourMembership";
import MakePaymentAddMembershipModal from "../../components/modal/MakePaymentAddMembershipModal";
import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import checkDateOverlap from "../../utils/checkDateOverlap";

const AddMembershipToMember = (props) => {
  const {
    getMembershipByLocationData,
    member,
    getMembershipByLocationId,
    createLog,
    getMemberById,
    setIndex,
    sidebarBrandId,
  } = useMemberContext();
  const navigate = useNavigate();
  const currentMemberContractId = useParams();
  const [membershipId, setMembershipId] = useState("");
  const [card, setCard] = useState("fileCard");
  const [futurePaymentMethod, setFuturePaymentMethod] = useState("file-card");
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [currentMembership, setCurrentMembership] = useState([]);
  const [selectedMembership, setSelectedMembership] = useState();
  const [delayPayment, setDeylayPayment] = useState(false);
  const [loading, setLoading] = useState(true);
  const [membershipStartDate, setMembershipStartDate] = useState(new Date());
  const [listHealthQuestion, setListHealthQuestion] = useState([]);
  const [isAccept, setIsAccept] = useState(false);
  const [step, setStep] = useState(0);
  const [isHaveInjury, setIsHaveInjury] = useState(false);
  const formikRef = useRef(null);
  const [getListHealthQuestion] = useLazyQuery(healthQuestionsByBrandId);
  const handleError = useHandleError();
  const [confirmBoxes, setConfirmBoxes] = useState({
    terms: false,
    pre_exercise: false,
  });
  const [age, setAge] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [voucherDetail, setVoucherDetail] = useState({});
  const [voucherCode, setVoucherCode] = useState("");
  const [currentPIFContract, setCurrentPIFContract] = useState({});
  const [pifPrice, setPIFPrice] = useState({
    costPrice: 0,
    joiningFee: 0,
    joiningFee2: 0,
    joiningFee3: 0,
  });
  const [minDateValidation, setMinDateValidateion] = useState("");
  const [timezone, setTimezone] = useState(
    member?.getMember?.homeLocationDetails?.timezone ?? "Australia/Sydney"
  );
  const [pifMembershipList, setPIFMembershipList] = useState([]);
  const checkMobile = () => {
    setIsMobile(window.innerWidth < 1585);
  };

  const calculateStatus = ({
    isPending,
    startDateTime,
    endDateTime,
    activeSuspension,
    isCancellationBeforeToday,
    isCancellationToday,
  }) => {
    if (isCancellationBeforeToday || isCancellationToday) return "CANCELLED";
    if (endDateTime) {
      const isExpired = isBefore(endOfDay(new Date(endDateTime)), new Date());
      if (isExpired) return "EXPIRED";
    }
    if (!!activeSuspension) return "SUSPENDED";

    if (isPending && !startDateTime) return "PENDING";
    if (
      startDateTime &&
      isAfter(
        startOfDay(utcToZonedTime(new Date(startDateTime), timezone)),
        new Date()
      )
    )
      return "FUTURE";

    return "ACTIVE";
  };

  window.addEventListener("resize", function () {
    checkMobile();
  });

  useEffect(() => {
    getMemberById();
    getListHealthQuestion({ fetchPolicy: "no-cache" }).then((res) => {
      const questions = res.data?.getHealthQuestionnairesByBrandId.items.map(
        (i) => {
          return { ...i, userValue: "" };
        }
      );
      let sortedQuestion = [...questions];
      sortedQuestion.push(
        sortedQuestion.splice(
          sortedQuestion.findIndex((i) =>
            i.question
              .toLowerCase()
              .includes(
                "I am under the supervision of a medical practitioner or I will seek guidance from an appropriate health or medical practitioner prior to undertaking exercise.".toLowerCase()
              )
          ),
          1
        )[0]
      );
      setListHealthQuestion(sortedQuestion);
      const pifMembershipCurrentData = member?.getMember?.contracts.items.find(
        (i) => {
          return i.id === currentMemberContractId.contractId;
        }
      );
      setCurrentPIFContract(pifMembershipCurrentData);

      if (pifMembershipCurrentData) {
        setMembershipId(pifMembershipCurrentData?.membershipId);
        setPIFPrice({
          costPrice: pifMembershipCurrentData?.costPrice,
          joiningFee: pifMembershipCurrentData?.joiningFee,
          joiningFee2: pifMembershipCurrentData?.joiningFee2,
          joiningFee3: pifMembershipCurrentData?.joiningFee3,
        });
        handlePIFMembershipStartDate(
          pifMembershipCurrentData.endDateTime,
          pifMembershipCurrentData.expiryDateTime
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [membershipList, setMembershipList] = useState([]);
  const [chargeSinglePayment, { loading: chargeSinglePaymentLoading }] =
    useMutation(CHARGE_SINGLE_PAYMENT);

  const [renewMembership, { loading: renewMembershipLoading }] =
    useMutation(RENEW_MEMBERSHIP);

  const filterCurrentMembership = (data) => {
    let status = "ACTIVE";

    const { isPending, startDateTime, endDateTime } = data;

    if (isPending && !startDateTime) status = "PENDING";
    if (startDateTime && isAfter(new Date(startDateTime), new Date()))
      status = "FUTURE";

    // let isCancellationAfterNow = false;
    let isCancellationToday = false;
    let isCancellationBeforeToday = false;
    const cancellationDate = data?.expiryDateTime;
    if (cancellationDate) {
      isCancellationToday = isSameDay(new Date(cancellationDate), new Date());
      isCancellationBeforeToday = isBefore(
        new Date(cancellationDate),
        new Date()
      );
    }
    if (isCancellationBeforeToday || isCancellationToday) status = "CANCELLED";
    if (endDateTime) {
      const isExpired = isBefore(endOfDay(new Date(endDateTime)), new Date());
      if (isExpired) return "EXPIRED";
    }
    return {
      status,
    };
  };
  const convertUTCtoAusTimezone = (utcDate, timeZone) => {
    return format(new Date(utcDate), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", {
      timeZone,
    });
  };
  useEffect(() => {
    const membershipCurrentData = member?.getMember?.contracts.items.filter(
      (i) => {
        return filterCurrentMembership(i).status === "ACTIVE";
      }
    );
    setCurrentMembership(membershipCurrentData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMembershipByLocationData]);

  const [getMembership] = useLazyQuery(GET_ALL_LOCATIONS_BY_LOCATIONID, {
    fetchPolicy: "no-cache",
    variables: {
      locationId: member?.getMember?.homeLocationId,
      limit: 1000,
      //   nextToken: nextToken,
    },
    onCompleted: (data) => {
      const age = differenceInYears(
        new Date(),
        new Date(member?.getMember?.dob) ?? new Date()
      );
      setAge(age);
      const tableItems = data?.getMembershipByLocationId.map((cur) => {
        const { items } = cur.membershipLocations;
        return items;
      });
      const flattendItems = tableItems.flat();
      const validDateMembership = flattendItems?.filter((item) => {
        const activeMembership =
          item.membershipDetails?.activationDates.items?.some((i) => {
            const timezone =
              member.getMember?.homeLocationDetails?.timezone ??
              "Australia/Sydney";
            const openingMembership = !isAfter(
              new Date(i.startDateTime),
              new Date()
            );
            const expiringMembership =
              (i.endDateTime &&
                !isBefore(
                  endOfDay(
                    new Date(convertUTCtoAusTimezone(i.endDateTime, timezone))
                  ),
                  new Date()
                )) ||
              !i.endDateTime;
            return openingMembership && expiringMembership;
          });
        // return activeMembership;

        if (!item.membershipDetails.isActive) return false;
        if (
          !currentMembership
            ?.map((i) => i.membershipId)
            .includes(item.membershipDetails.id)
        ) {
          if (age >= 16) {
            //more than 16 is adult membership showed
            return (
              item.membershipDetails.membershipLevel === "ADULT" &&
              activeMembership
            );
          } else if (age > 13 && age < 16) {
            //less than 16 and more than 13 is youth membership showed
            return (
              item.membershipDetails.membershipLevel === "YOUTH" &&
              activeMembership
            );
          } else if (age <= 13) {
            //less than 13 is junior membership showed
            return (
              item.membershipDetails.membershipLevel === "JUNIOR" &&
              activeMembership
            );
          }
          return false;
        } else {
        }
      });
      const filterPIFMembership = flattendItems.filter(
        (item) => !item.membershipDetails.recurring
      );
      setPIFMembershipList(filterPIFMembership);
      if (filterPIFMembership.length > 0) {
        const currentPIFMembership = filterPIFMembership.find(
          (item) =>
            item.membershipDetails.id === currentPIFContract?.membershipId
        );
        setSelectedMembership(currentPIFMembership);
      }
      setMembershipList(validDateMembership);
      setLoading(false);
    },
  });

  const { data: locationDetail } = useQuery(GET_LOCTAION_BY_ID, {
    fetchPolicy: "no-cache",
    variables: { id: member?.getMember?.homeLocationId },
  });

  useEffect(() => {
    if (member && member?.getMember && member?.getMember?.homeLocationId) {
      getMembershipByLocationId(member.getMember.homeLocationId)
        .then((res) => {
          getMembership();
          // setLoading(false);
        })
        .catch((e) => setLoading(false));

      const maintenanceDates =
        locationDetail?.getLocation?.locationMaintenance?.items.filter(
          (maintenance) => !maintenance.isDeleted
        );

      const overlappedMaintenance = maintenanceDates?.find(
        ({ startDateTime, endDateTime }) => {
          return checkDateOverlap(new Date(), {
            start: startDateTime,
            end: endDateTime,
          });
        }
      );
      if (
        isAfter(
          new Date(member?.getMember?.homeLocationDetails?.openDate),
          new Date()
        )
      ) {
        setMembershipStartDate(
          new Date(member?.getMember?.homeLocationDetails?.openDate)
        );
      } else if (overlappedMaintenance) {
        const maintenanceEnd =
          overlappedMaintenance && overlappedMaintenance.endDateTime
            ? add(new Date(overlappedMaintenance.endDateTime), { days: 1 })
            : null;
        if (maintenanceEnd) setMembershipStartDate(maintenanceEnd);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member, locationDetail]);

  const handleMembershipType = (membershipId) => {
    setMembershipId(membershipId);
    setSelectedMembership(
      membershipList.find((item) => item?.membershipDetails.id === membershipId)
    );

    setDeylayPayment(
      membershipList.find((item) => item?.membershipDetails.id === membershipId)
        ?.membershipDetails?.paymentDelay?.amount
    );
  };
  const handleSelectPIFMembership = (membershipDetails) => {
    setMembershipId(membershipDetails.membershipDetails.id);
    setSelectedMembership(membershipDetails);
    setDeylayPayment(
      membershipDetails?.membershipDetails?.paymentDelay !== null
    );
    setPIFPrice({
      costPrice: membershipDetails?.costPrice ?? 0,
      joiningFee: membershipDetails?.joiningFee ?? 0,
      joiningFee2: membershipDetails?.activationFee ?? 0,
      joiningFee3: membershipDetails?.fobFee ?? 0,
    });
  };

  //query to get selected membership data
  const [getMembershipByID, { data: getMembershipByIdData }] = useLazyQuery(
    GET_MEMBERSHIP_TYPE_BY_ID,
    {
      fetchPolicy: "no-cache",
      variables: { id: `${membershipId}` },
    }
  );
  useEffect(() => {
    setCard("fileCard");
    setFuturePaymentMethod("file-card");
    getMembershipByID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipId]);

  useEffect(() => {
    createLog(
      `Access addMembershipToMember for ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
      "member",
      `${member?.getMember?.memberId}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddMembershipSubmit = (values, OnSubmitProps) => {
    const paymentInformationId = member?.getMember?.paymentDetails?.items.find(
      (item) => item.primary
    )?.id;
    let variables = {
      memberId: member?.getMember?.memberId,
      brandId: sidebarBrandId,
      addMembershipArguments: {
        membershipStartDate: format(
          isAfter(
            new Date(member?.getMember?.homeLocationDetails?.openDate),
            new Date()
          )
            ? new Date(member?.getMember?.homeLocationDetails?.openDate)
            : membershipStartDate
            ? new Date(membershipStartDate)
            : new Date(),
          "yyyy-MM-dd"
        ),
        newLocationId: member?.getMember?.homeLocationDetails?.id,
        newMembershipId: membershipId,
        healthQuestionnaire: JSON.stringify(
          listHealthQuestion.reduce(
            (obj, item) => ({ ...obj, [item.id]: item.userValue }),
            {}
          )
        ),
        voucherCode: voucherDetail?.voucherCode,
        invoice: "",
      },
      amount: 0,
      operation: "AMS",
      paymentInformationId: paymentInformationId,
      futurePaymentOption: "CARD_ON_FILE_IS_DEFAULT",
    };
    if (values.membershipStartDate) {
      variables.membershipStartDate = values.membershipStartDate;
    }

    chargeSinglePayment({
      variables: { ...variables },
      onCompleted: () => {
        OnSubmitProps.setSubmitting(false);
        OnSubmitProps.resetForm();
        toast.success("Membership added to member successfully");
        setIndex(1);
        navigate(`/dashboard/member/${member?.getMember?.memberId}`);
      },
      onError: () => {
        OnSubmitProps.setSubmitting(false);
        handleError("Error: Membership cannot be added");
      },
    });
  };
  const onRenewMembershipSubmit = async (values, OnSubmitProps) => {
    const paymentInformation = member?.getMember?.paymentDetails?.items.find(
      (item) => item.primary
    );
    let variables = {
      memberId: member?.getMember?.memberId,
      amount: 0,
      membershipId: membershipId,
      locationId: member?.getMember?.homeLocationDetails?.id,
      membershipLocationId: currentPIFContract.membershipLocationId,
      membershipStartDate: new Date(membershipStartDate),
      membershipAmount: 0,
      // memberContractId: currentPIFContract.id,
      paymentType: paymentInformation?.paymentType ?? "CREDIT_CARD",
      nextPaymentType: paymentInformation?.paymentType ?? "CREDIT_CARD",
      firstPaymentInformationId: paymentInformation?.id ?? "",
      nextPaymentInformationId: paymentInformation?.id ?? "",
      joiningFee: 0,
      fobFee: 0,
      activationFee: 0,
    };

    await renewMembership({
      variables: { ...variables },
      onCompleted: () => {
        OnSubmitProps.setSubmitting(false);
        OnSubmitProps.resetForm();
        toast.success("Renew membership successfully");
        setIndex(1);
        navigate(`/dashboard/member/${member?.getMember?.memberId}`);
      },
      onError: () => {
        OnSubmitProps.setSubmitting(false);
        handleError("Error: Membership cannot be renew");
      },
    });
  };
  const addMembershipSuccess = () => {
    toast.success("Membership added to member successfully");
    setIndex(1);
    getMemberById();
    navigate(`/dashboard/member/${member?.getMember?.memberId}`);
  };
  const addMembershipErr = (err) => {
    handleError(
      err?.message
        ? "Error: " + err.message
        : "Error: Membership cannot be added"
    );
  };
  const renewMembershipSuccess = () => {
    toast.success("Renew membership successfully");
    setIndex(1);
    navigate(`/dashboard/member/${member?.getMember?.memberId}`);
  };
  const renewMembershipErr = (err) => {
    handleError(
      err?.message
        ? "Error: " + err.message
        : "Error: Membership cannot be renew"
    );
  };
  const refetchQueries = [
    {
      query: GET_MEMBER_BY_ID,
      variables: {
        memberId: member?.getMember?.memberId,
      },
    },
  ];
  const handleCancel = () => {
    navigate(`/dashboard/member/${member?.getMember?.memberId}`);
  };
  let fileCardInfo;
  if (member?.getMember?.paymentDetails.items !== 0) {
    const fileCard = member?.getMember?.paymentDetails.items.find(
      (i) => i.paymentType === "CREDIT_CARD"
    );
    fileCardInfo = fileCard
      ? {
          paymentType: fileCard.paymentType,
          cardNumber: fileCard.cardNumber,
          cardExpiryDate: fileCard.cardExpiryDate,
          cardCvv: fileCard.cardCvv,
          cardType: fileCard.cardType,
          cardHolderName: fileCard.cardHolderName,
        }
      : null;
  }
  const findMembershipLocationCost = (arr) => {
    const location = arr?.find(
      (cur) => membershipId === cur.membershipDetails?.id
    );
    return location;
  };
  const calculatePrice = () => {
    let finalPriceOfMembership = {};
    const priceFromLocation = findMembershipLocationCost(membershipList);
    const priceFromMembership = {
      costPrice: getMembershipByIdData?.getMembership.costPrice,
      joiningFee: getMembershipByIdData?.getMembership.joiningFee,
      activationFee: getMembershipByIdData?.getMembership.joiningFee2,
      fobFee: getMembershipByIdData?.getMembership.joiningFee3,
    };

    // if 3 of the fee belong to the location is === null (which happened in old very old membership location)
    // we will populate the price from the membership instead of the price in the location
    if (
      priceFromLocation?.joiningFee === null &&
      priceFromLocation?.activationFee === null &&
      priceFromLocation?.fobFee === null
    ) {
      finalPriceOfMembership = priceFromMembership;
    } else {
      finalPriceOfMembership = priceFromLocation;
    }

    const voucherExists = Object.keys(voucherDetail || {}).length > 0;
    let totalPrice = 0;
    let membershipPrice = 0;
    let joiningFee = 0;
    let activationFee = 0;
    let accessFee = 0;

    if (voucherExists) {
      const discountPercentage = voucherDetail?.cost * 0.01;
      const originalPrice = voucherDetail?.membershipDetail?.costPrice;
      const discountedPrice =
        originalPrice - originalPrice * discountPercentage;
      membershipPrice =
        voucherDetail.type === "PERCENT"
          ? parseFloat(discountedPrice?.toFixed(2)) || 0
          : parseFloat(voucherDetail.cost?.toFixed(2)) || 0;
      joiningFee = voucherDetail?.joiningFee || 0;
      activationFee = voucherDetail?.activationFee || 0;
      accessFee = voucherDetail?.passFee || 0;

      totalPrice = membershipPrice + joiningFee + activationFee + accessFee;
    } else {
      const pifPriceNumber = {
        costPrice: parseFloat(pifPrice?.costPrice) || 0,
        joiningFee: parseFloat(pifPrice?.joiningFee) || 0,
        joiningFee2: parseFloat(pifPrice?.joiningFee2) || 0,
        joiningFee3: parseFloat(pifPrice?.joiningFee3) || 0,
      };

      totalPrice = currentPIFContract
        ? pifPriceNumber.costPrice +
          pifPriceNumber?.joiningFee +
          pifPriceNumber?.joiningFee2 +
          pifPriceNumber?.joiningFee3
        : delayPayment
        ? (finalPriceOfMembership?.joiningFee ??
            finalPriceOfMembership?.joiningFee) +
          (finalPriceOfMembership?.activationFee ??
            finalPriceOfMembership?.activationFee) +
          (finalPriceOfMembership?.fobFee ?? finalPriceOfMembership?.fobFee)
        : priceFromLocation?.costPrice +
          (finalPriceOfMembership?.joiningFee ??
            finalPriceOfMembership?.joiningFee) +
          (finalPriceOfMembership?.activationFee ??
            finalPriceOfMembership?.activationFee) +
          (finalPriceOfMembership?.fobFee ?? finalPriceOfMembership?.fobFee);
      membershipPrice =
        parseFloat(
          Number(
            currentPIFContract
              ? pifPriceNumber.costPrice
              : delayPayment
              ? 0
              : priceFromLocation?.costPrice
          ).toFixed(2)
        ) || 0;
      joiningFee = currentPIFContract
        ? pifPriceNumber?.joiningFee
        : finalPriceOfMembership?.joiningFee;

      activationFee = currentPIFContract
        ? pifPriceNumber?.joiningFee2
        : finalPriceOfMembership?.activationFee;
      accessFee = currentPIFContract
        ? pifPriceNumber?.joiningFee3
        : finalPriceOfMembership?.fobFee;
    }
    return {
      totalPrice: totalPrice.toFixed(2),
      membershipPrice: membershipPrice.toFixed(2),
      joiningFee: Number(joiningFee).toFixed(2),
      activationFee: Number(activationFee).toFixed(2),
      accessFee: Number(accessFee).toFixed(2),
    };
  };

  const handleMinMaxDate = (data) => {
    let minDate;
    let maxDate;

    const currentDate = new Date();

    if (isAfter(startOfDay(new Date(data)), startOfDay(currentDate))) {
      minDate = format(startOfDay(new Date(data)), "yyyy-MM-dd");
    } else {
      minDate = format(startOfDay(currentDate), "yyyy-MM-dd");
    }

    const minDateObject = new Date(minDate);
    maxDate = format(addMonths(minDateObject, 3), "yyyy-MM-dd");

    return { minDate, maxDate };
  };
  const healthCheck = () => {
    if (
      listHealthQuestion.every((i) =>
        i.answerType === "NUMBER"
          ? i.mandatory
            ? i.expectedAnswer !== ""
              ? i.userValue === i.expectedAnswer
              : i.userValue !== ""
            : true
          : i.answerType === "STRING"
          ? i.mandatory
            ? i.userValue !== ""
            : true
          : false
      )
    ) {
      return true;
    }
    return false;
  };
  const handleNextStep = () => {
    const formik = formikRef.current;

    switch (step) {
      case 0:
        handleStep0();
        break;
      case 1:
        handleStep1();
        break;
      case 2:
        handleStep2();
        break;
      default:
        break;
    }

    function handleStep0() {
      if (membershipId && membershipStartDate) {
        setStep(step + 1);
      } else if (!membershipId) {
        handleError("Please select a membership");
      } else if (!membershipStartDate) {
        handleError("Please select start date");
      }
    }

    function handleStep1() {
      if (areAllConfirmBoxesChecked()) {
        if (isHaveInjury && !healthCheck()) {
          handleError("Please answer the health questions");
          return;
        }
        setStep(step + 1);
      } else {
        handleError("Please accept our Terms and Conditions");
      }
    }

    function handleStep2() {
      if (isAccept) {
        formik.submitForm();
      } else {
        handleError("Please accept terms and conditions");
      }
    }

    function areAllConfirmBoxesChecked() {
      return Object.values(confirmBoxes).every((i) => i);
    }
  };
  const handleInputChangePIFPrice = (event, propertyName) => {
    const { value } = event.target;
    if (!isNaN(value)) {
      setPIFPrice((prevState) => ({
        ...prevState,
        [propertyName]: value,
      }));
    }
  };
  const handlePIFMembershipStartDate = (endDateTime, expiryDateTime) => {
    const endDateTimeConverted = parseISO(endDateTime);
    const expiryDateTimeConverted = parseISO(expiryDateTime);

    const earliestDate =
      endDateTimeConverted < expiryDateTimeConverted || !expiryDateTime
        ? endDateTime
        : expiryDateTime;
    const convertTimezone = formatInTimeZone(
      new Date(earliestDate),
      timezone,
      "yyyy-MM-dd"
    );
    const nextDay = new Date(handleMinMaxDate(convertTimezone).minDate);
    if (
      isAfter(startOfDay(new Date(convertTimezone)), startOfDay(new Date()))
    ) {
      nextDay.setDate(nextDay.getDate() + 1);
    }
    setMinDateValidateion(format(nextDay, "yyyy-MM-dd"));
    setMembershipStartDate(format(nextDay, "yyyy-MM-dd"));
  };

  if (loading) {
    return (
      <div className="dashboard">
        <div className="dashboard-loading">
          <BeatLoader color="white" />
        </div>
      </div>
    );
  }
  if (membershipList?.length === 0 && !loading) {
    return (
      <Modal>
        <StatusModal
          modalHeaderTxt={"No membership available"}
          modalParaText={"Current location does not have active memberships."}
          showOneBtn={true}
          oneButtonName="Go Back"
          modalClose={() => navigate(-1)}
        />
      </Modal>
    );
  }
  return (
    <>
      {!loading && (
        <div className="add-membership-to-member">
          <Header
            pageTitle={
              currentPIFContract ? "Renew membership" : "Add A Membership"
            }
          />
          <div className="member-details-info mb-32">
            <div className="name fs-35 mt-32">
              {member?.getMember?.givenName} {member?.getMember?.surname}
            </div>
          </div>

          <Formik
            enableReinitialize
            innerRef={formikRef}
            initialValues={{
              membershipStartDate: "",
              membershipName: "",
              costPrice: "",
            }}
            onSubmit={(values, OnSubmitProps) => {
              if (
                (!calculatePrice()?.joiningFee &&
                  !calculatePrice()?.activationFee &&
                  !calculatePrice()?.accessFee &&
                  delayPayment) ||
                member?.getMember.type === "STAFF" ||
                !Number(calculatePrice()?.totalPrice)
              ) {
                if (!currentPIFContract) {
                  onAddMembershipSubmit(values, OnSubmitProps);
                } else {
                  onRenewMembershipSubmit(values, OnSubmitProps);
                }
              } else {
                setShowModalPayment(true);
              }
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <div
                    className={`add-membership-process ${
                      (isMobile || (currentPIFContract && step === 0)) &&
                      "block"
                    }`}
                  >
                    <div className="add-membership-container">
                      {step === 0 && (
                        <div>
                          {currentPIFContract ? (
                            <div className="main-membership-card mt-32 pd-12">
                              <div className="variable-selectors">
                                <div className="membership-type-box">
                                  <div className="membership-type">
                                    <label className="fs-14 uppercase mb-12">
                                      Membership Details:
                                    </label>
                                    <div className="member-grid-2">
                                      <Input
                                        type="date"
                                        label="Select Start Date"
                                        name="membershipStartDate"
                                        id="membershipStartDate"
                                        className="start-date"
                                        autoComplete="off"
                                        onChange={(e) =>
                                          setMembershipStartDate(e.target.value)
                                        }
                                        value={membershipStartDate}
                                        min={minDateValidation}
                                      />

                                      <div className="membership-select ">
                                        {membershipList && (
                                          <Select
                                            name="membershipType"
                                            optionvalues={pifMembershipList.map(
                                              (cur) => {
                                                return {
                                                  membershipDetails:
                                                    cur.membershipDetails,
                                                  membershipName:
                                                    cur.membershipDetails
                                                      .membershipName,
                                                  id: cur?.membershipDetails
                                                    ?.id,
                                                };
                                              }
                                            )}
                                            label="Select a Membership"
                                            objectvalue="id"
                                            objectname="membershipName"
                                            selecttype="object"
                                            value={membershipId}
                                            onChange={(e) => {
                                              const selectedMembership =
                                                pifMembershipList.find(
                                                  (membership) =>
                                                    membership.membershipDetails
                                                      .id === e.target.value
                                                );
                                              if (selectedMembership) {
                                                handleSelectPIFMembership(
                                                  selectedMembership
                                                );
                                              }
                                            }}
                                            className="select-element-value main fs-12"
                                          />
                                        )}
                                      </div>
                                    </div>
                                    {(selectedMembership ||
                                      currentPIFContract) && (
                                      <>
                                        <div className="membership-details">
                                          <div className="first-row">
                                            <InputWithoutFormik
                                              type="text"
                                              label="Cost Price $"
                                              name="costPrice"
                                              id="costPrice"
                                              value={pifPrice.costPrice}
                                              onChange={(e) =>
                                                handleInputChangePIFPrice(
                                                  e,
                                                  "costPrice"
                                                )
                                              }
                                            />
                                            <InputWithoutFormik
                                              type="text"
                                              label="Joining Fee $"
                                              name="joiningFee"
                                              id="joiningFee"
                                              value={pifPrice?.joiningFee}
                                              onChange={(e) =>
                                                handleInputChangePIFPrice(
                                                  e,
                                                  "joiningFee"
                                                )
                                              }
                                            />
                                            <InputWithoutFormik
                                              type="text"
                                              label="Activation Fee $"
                                              name="activationFee"
                                              id="activationFee"
                                              value={pifPrice?.joiningFee2}
                                              onChange={(e) =>
                                                handleInputChangePIFPrice(
                                                  e,
                                                  "joiningFee2"
                                                )
                                              }
                                            />
                                            <InputWithoutFormik
                                              type="number"
                                              label="FOB $"
                                              name="fobFee"
                                              id="fobFee"
                                              value={pifPrice?.joiningFee3}
                                              onChange={(e) =>
                                                handleInputChangePIFPrice(
                                                  e,
                                                  "joiningFee3"
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <MembershipList
                              membershipList={membershipList}
                              handleMembershipType={handleMembershipType}
                              membershipId={membershipId}
                              voucherDetail={voucherDetail}
                              setVoucherDetail={setVoucherDetail}
                              voucherCode={voucherCode}
                              setVoucherCode={setVoucherCode}
                              memberDetails={member.getMember}
                            />
                          )}
                        </div>
                      )}
                      {step === 1 && (
                        <HealthQuestionaires
                          listHealthQuestion={listHealthQuestion}
                          setListHealthQuestion={setListHealthQuestion}
                          isHaveInjury={isHaveInjury}
                          setIsHaveInjury={setIsHaveInjury}
                          confirmBoxes={confirmBoxes}
                          setConfirmBoxes={setConfirmBoxes}
                        />
                      )}
                      {step === 2 && <Payment setIsAccept={setIsAccept} />}
                      <div
                        className={`button-navigate-container ${
                          step === 0 ? "align-right" : ""
                        }`}
                      >
                        {step > 0 && (
                          <Button
                            click={() => setStep(step - 1)}
                            name="prev"
                            btntype="button"
                            btnname="button"
                            className="btn btn-transparent btn-small"
                          />
                        )}

                        <Button
                          name={step !== 2 ? "Next" : "Pay"}
                          btntype="button"
                          btnname="button"
                          className="btn btn-transparent btn-small"
                          loading={
                            chargeSinglePaymentLoading || renewMembershipLoading
                          }
                          click={handleNextStep}
                        />
                      </div>
                      {step !== 0 && isMobile && (
                        <div
                          className={`your-membership-mobile ${
                            isExpand ? "expanded" : ""
                          }`}
                          onClick={() => !isExpand && setIsExpand(!isExpand)}
                        >
                          {isExpand ? (
                            <div className="container">
                              <div
                                className="collapse-btn"
                                onClick={() => setIsExpand(false)}
                              ></div>
                              <YourMembership
                                currentPIFContract={currentPIFContract}
                                setMembershipStartDate={setMembershipStartDate}
                                membershipStartDate={membershipStartDate}
                                membershipDetails={selectedMembership}
                                locationDetails={
                                  member?.getMember?.homeLocationDetails
                                }
                                age={age}
                                membershipPrice={calculatePrice()}
                                voucherDetail={voucherDetail}
                              />
                            </div>
                          ) : (
                            <div className="title">Your Membership</div>
                          )}
                        </div>
                      )}
                    </div>

                    {step !== 0 && !isMobile && (
                      <div className="your-membership">
                        <YourMembership
                          currentPIFContract={currentPIFContract}
                          setMembershipStartDate={setMembershipStartDate}
                          membershipStartDate={membershipStartDate}
                          membershipDetails={selectedMembership}
                          locationDetails={
                            member?.getMember?.homeLocationDetails
                          }
                          age={age}
                          voucherDetail={voucherDetail}
                          membershipPrice={calculatePrice()}
                        />
                      </div>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
          {showModalPayment && !currentPIFContract && (
            <MakePaymentAddMembershipModal
              amount={calculatePrice()?.totalPrice}
              membershipType={
                getMembershipByIdData?.getMembership.membershipType ===
                "FITNESS_PASSPORT"
                  ? "fitnessPassport"
                  : getMembershipByIdData?.getMembership.recurring &&
                    voucherDetail?.type !== "COMPLIMENTARY"
                  ? "weekly"
                  : "pif"
              }
              operation="AMS"
              setShowModalPayment={setShowModalPayment}
              locationId={
                member?.getMember?.contracts?.items[0]?.locationId
                  ? member?.getMember?.contracts?.items[0]?.locationId
                  : selectedMembership?.membershipLocations?.items[0].locationId
              }
              payloadData={{
                addMembershipArguments: {
                  membershipStartDate: format(
                    isAfter(
                      new Date(
                        member?.getMember?.homeLocationDetails?.openDate
                      ),
                      new Date()
                    )
                      ? new Date(
                          member?.getMember?.homeLocationDetails?.openDate
                        )
                      : membershipStartDate
                      ? new Date(membershipStartDate)
                      : new Date(),
                    "yyyy-MM-dd"
                  ),
                  newLocationId: member?.getMember?.homeLocationDetails?.id,
                  newMembershipId: membershipId,
                  healthQuestionnaire: JSON.stringify(
                    listHealthQuestion.reduce(
                      (obj, item) => ({ ...obj, [item.id]: item.userValue }),
                      {}
                    )
                  ),
                  voucherCode: voucherDetail?.voucherCode,
                  invoice: "",
                },
              }}
              onApiCompleted={() => addMembershipSuccess()}
              onApiError={(e) => addMembershipErr(e)}
              apiRefetchQueries={refetchQueries}
            />
          )}
          {showModalPayment && currentPIFContract && (
            <MakePaymentAddMembershipModal
              amount={calculatePrice()?.totalPrice}
              membershipType={
                getMembershipByIdData?.getMembership.recurring
                  ? "weekly"
                  : "pif"
              }
              operation="RNM"
              setShowModalPayment={setShowModalPayment}
              locationId={
                member?.getMember?.contracts.items[0]?.locationId
                  ? member?.getMember?.contracts.items[0]?.locationId
                  : selectedMembership?.membershipLocations.items[0].locationId
              }
              payloadData={{
                renewMembershipArguments: {
                  membershipStartDate: new Date(membershipStartDate),
                  locationId: member?.getMember?.homeLocationDetails?.id,
                  membershipId: membershipId,
                  membershipAmount: Number(calculatePrice()?.membershipPrice),
                  // memberContractId: currentPIFContract.id,
                  membershipLocationId: selectedMembership?.id
                    ? selectedMembership?.id
                    : currentPIFContract.membershipLocationId,
                  joiningFee: Number(calculatePrice()?.joiningFee),
                  activationFee: Number(calculatePrice()?.activationFee),
                  fobFee: Number(calculatePrice()?.accessFee),
                },
              }}
              onApiCompleted={() => renewMembershipSuccess()}
              onApiError={(e) => renewMembershipErr(e)}
              apiRefetchQueries={refetchQueries}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AddMembershipToMember;
