import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import EXCELExport from "../export/EXCELExport";
import CSVExport from "../export/CSVExport";
// import PDFExportPrint from "../export/PDFExportPrint";
// import CopyToClipboard from "../export/CopyToClipboard";
import getTypeAbbreviation from "../../utils/getTypeAbbreviation";
import { brandTypes } from "../../constants/commonConstant";

export default function ExportGroup(props) {
  const { globalFilter, setGlobalFilter, sorting, footerForExport } = props;
  const tableExport = useReactTable({
    data: props.data,
    columns: props.columns,
    state: {
      sorting,
      globalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: "includesString",
    getFilteredRowModel: getFilteredRowModel(),
  });
  const exportHeader = tableExport
    .getHeaderGroups()[0]
    .headers.map((header) => {
      if (typeof header.column.columnDef.header !== "string") {
        return null;
      }
      if (
        header.column.columnDef.header === "LINKS PHOTO" ||
        header.column.columnDef.header === "SWIPE PHOTO"
      ) {
        return null;
      }
      return header.column.columnDef.header;
    });
  const exportBody = tableExport.getRowModel().rows.map((row) => {
    const rowArray = row.getVisibleCells().map((cell) => {

      switch (cell.column.id) {
        case "is24hour":
          return cell.getValue() ? "24H" : "";
        case "branddetails":
          const brandName = cell.getValue() ? cell.getValue().name : "";
          const abbreviation = getTypeAbbreviation(brandName, brandTypes);
          return abbreviation;
        case "bookable":
        case "active":
          return cell.getValue() ? "YES" : "NO";
        //Reporting table (All members, Blocked)
        case "suspensionstartdate1":
          const suspensionstartdate1 = cell.getValue()
            ? cell.getValue()?.items?.[0]?.suspensionStartDate
            : "";
          return suspensionstartdate1;
        case "suspensionenddate1":
          const suspensionenddate1 = cell.getValue()
            ? cell.getValue()?.items?.[0]?.suspensionEndDate
            : "";
          return suspensionenddate1;
        case "reason":
          const reason = cell.getValue()
            ? cell.getValue().replaceAll(",", "\n")
            : "";
          return reason;

        case "fpnumber":
          const fpnumber = cell.getValue();

          return fpnumber;
        case "debitDate":
        case "refundDate":
        case "expiry":
          const dates = cell.getValue()
            ? cell.getValue().replaceAll(",", ", ")
            : "";
          return dates;
        case "totalDirectDebits":
          const totalDirectDebits = `${
            cell.row.original.totalAmount.toFixed(2) ?? ""
          } ${
            cell.getValue() && cell.getValue().includes(",")
              ? `(${cell.getValue()})`
              : ""
          }`;
          return totalDirectDebits;
        case "totalRefunds":
        case "totalAmount":
          const item = cell.getValue()
            ? cell.getValue().replaceAll(",", ", ")
            : "";
          return item;
        case "swipePhoto":
          return;
        case "memberPhoto":
          return;
        case "locationVisit":
          let deviceName = "OTHER";
          if (cell.getValue()?.deviceType === "DOOR") deviceName = "FOB";
          if (cell.getValue()?.deviceType === "MOBILE") deviceName = "APP";
          return `${cell.getValue()?.doorName} - ${deviceName} - ${
            cell.getValue()?.accessPoint
          }`;
        default:
          return cell.getValue() ? cell.getValue() : 0;
      }
    });
    return rowArray;
  });
  const exportFooter = tableExport.getFooterGroups().map((row) => {
    const rowArray = row.headers.map((cell) => {
      switch (cell.column.id) {
        case "totalAmount":
          return footerForExport.totalAmount ?? "";
        case "totalRefunds":
          return footerForExport.totalRefunds ?? "";
        case "totalDirectDebits":
          const totalDirectDebits = tableExport
            .getFilteredRowModel()
            .rows.reduce(
              (total, row) =>
                Number(total) + Number(row.getValue("totalDirectDebits")),
              0
            )
            .toFixed(2);
          return footerForExport.totalDirectDebits ?? totalDirectDebits;
        case "outstanding":
          return footerForExport.outstanding ?? "";
        case "collected":
          return footerForExport.collected ?? "";
        case "expected":
          return footerForExport.expected ?? "";
        default:
          return cell.isPlaceholder
            ? null
            : flexRender(cell.column.columnDef.footer, cell.getContext());
      }
    });

    return rowArray;
  });
  const exportData = [exportHeader, ...exportBody, ...exportFooter];

  return (
    <div className="btn-group">
      {/* <CopyToClipboard data={exportData} /> */}
      <EXCELExport
        data={exportData}
        fileName="MyExcel.xlsx"
        sheetName="MySheet1"
      />
      <CSVExport data={exportData} fileName="MyCSV.csv" />
      {/* <PDFExportPrint
        header={exportHeader}
        body={exportBody}
        fileName="MyPDF"
      /> */}
    </div>
  );
}
