import { gql } from "@apollo/client";

export const SEARCH_MEMBERS = gql`
  query listMembers($filters: ModelMemberFilterInput, $nextToken: String) {
    listMembers(filter: $filters, nextToken: $nextToken) {
      items {
        aliasMemberId
        givenName
        email
        mobileNumber
        type
        dob
        memberId
        contract {
          items {
            voucherDetail {
              id
            }
          }
        }
      }
      nextToken
      total
    }
  }
`;

export const SEARCH_BY_AlIAS_ID = gql`
  query getMemberByAliasId($aliasMemberId: Int!, $nextToken: String) {
    getMemberByAliasId(aliasMemberId: $aliasMemberId, nextToken: $nextToken) {
      items {
        aliasMemberId
        givenName
        email
        mobileNumber
        type
        dob
        surname
        memberId
        brandDetails {
          name
        }
        homeLocationDetails {
          name
        }
      }
      nextToken
    }
  }
`;

export const SEARCH_BY_SURNAME = gql`
  query getMemberBySurname($surname: String!, $nextToken: String, $limit: Int) {
    getMemberBySurname(
      surname: $surname
      nextToken: $nextToken
      limit: $limit
    ) {
      items {
        aliasMemberId
        givenName
        email
        mobileNumber
        type
        dob
        surname
        memberId
        brandDetails {
          name
        }
        homeLocationDetails {
          id
          name
        }
      }
      nextToken
    }
  }
`;

export const SEARCH_BY_MOBILE = gql`
  query getMemberByMobile($mobileNumber: AWSPhone!, $nextToken: String) {
    getMemberByMobile(mobileNumber: $mobileNumber, nextToken: $nextToken) {
      items {
        aliasMemberId
        givenName
        email
        mobileNumber
        type
        dob
        surname
        memberId
        brandDetails {
          name
        }
        homeLocationDetails {
          name
        }
      }
      nextToken
    }
  }
`;

export const SEARCH_BY_EMAIL = gql`
  query getMemberByEmail(
    $email: AWSEmail!
    $nextToken: String
    $filter: ModelMemberFilterInput
    $limit: Int
  ) {
    getMemberByEmail(
      email: $email
      nextToken: $nextToken
      filter: $filter
      limit: $limit
    ) {
      items {
        memberId
        accepted
        dob
        role
        memberId
        type
        email
        givenName
        surname
        mobileNumber
        homeLocationId
        joinedDateTime
        state
        accepted
        contracts {
          items {
            id
            isPending
            startDateTime
            endDateTime
            expiryDateTime
            createdAt
            isFitnessPassport
            fitnessPassportNumber
            suspensions {
              items {
                suspensionType
                suspensionStatus
                suspensionStartDateTime
                suspensionEndDateTime
                cancelledDateTime
              }
            }
            membershipDetails {
              membershipType
              membershipName
            }
          }
        }
        createdAt
        homeLocationDetails {
          name
        }
        brandDetails {
          name
        }
      }
      nextToken
    }
  }
`;

export const SEARCH_BY_MEMBER_ALIAS_ID = gql`
  query getMemberByAliasId(
    $aliasMemberId: Int!
    $nextToken: String
    $filter: ModelMemberFilterInput
    $limit: Int
  ) {
    getMemberByAliasId(
      aliasMemberId: $aliasMemberId
      nextToken: $nextToken
      filter: $filter
      limit: $limit
    ) {
      items {
        memberId
        accepted
        dob
        role
        memberId
        type
        email
        givenName
        surname
        mobileNumber
        joinedDateTime
        state
        accepted
        isActive
        createdAt
        homeLocationId
        homeLocationDetails {
          name
        }
        brandDetails {
          name
        }
        contracts {
          items {
            id
            isPending
            startDateTime
            endDateTime
            expiryDateTime
            createdAt
            isFitnessPassport
            fitnessPassportNumber
            suspensions {
              items {
                suspensionType
                suspensionStatus
                suspensionStartDateTime
                suspensionEndDateTime
                cancelledDateTime
              }
            }
            membershipDetails {
              membershipType
              membershipName
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const SEARCH_MEMBER_BY_CARD_NUMBER = gql`
  query getMembersByCardNumber(
    $cardNumber: String!
    $operationName: String!
    $homeLocationIds: [String]
  ) {
    getMembersByCardNumber(
      cardNumber: $cardNumber
      operationName: $operationName
      homeLocationIds: $homeLocationIds
    ) {
      message
      statusCode
      data {
        dob
        role
        memberId
        type
        email
        givenName
        surname
        mobileNumber
        joinedDateTime
        state
        accepted
        isActive
        createdAt
        homeLocationId
        homeLocationDetails {
          name
        }
        brandDetails {
          name
        }
        contracts {
          items {
            id
            isPending
            startDateTime
            endDateTime
            expiryDateTime
            createdAt
            isFitnessPassport
            fitnessPassportNumber
            suspensions {
              items {
                suspensionType
                suspensionStatus
                suspensionStartDateTime
                suspensionEndDateTime
                cancelledDateTime
              }
            }
            membershipDetails {
              membershipType
              membershipName
            }
          }
        }
      }
    }
  }
`;
export const LIST_LOCATION = gql`
  query listLocations(
    $nextToken: String
    $sortDirection: ModelSortDirection
    $limit: Int
  ) {
    listLocations(
      nextToken: $nextToken
      sortDirection: $sortDirection
      filter: { status: { eq: ACTIVE } }
      limit: $limit
    ) {
      items {
        id
        is24Hour
        name
        state
        createdAt
        brandDetails {
          name
        }
        managerDetails {
          givenName
          surname
        }
        areaManagerDetails {
          givenName
          surname
        }
      }
      nextToken
    }
  }
`;

export const SEARCH_LOCATION = gql`
  query searchLocations(
    $filters: SearchableLocationFilterInput
    $sort: [SearchableLocationSortInput]
    $nextToken: String
    $limit: Int
  ) {
    searchLocations(
      filter: $filters
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        originNumber
        is24Hour
        name
        state
        createdAt
        brandDetails {
          name
        }
        managerDetails {
          givenName
          surname
        }
        areaManagerDetails {
          givenName
          surname
        }
      }
      nextToken
    }
  }
`;

export const LIST_DOORS = gql`
  query ($limit: Int) {
    listAccessPoints(limit: $limit) {
      items {
        id
        accessPointname
        doorName
        locationId
      }
      nextToken
    }
  }
`;

export const GET_DOOR_ACCESS_CODE_BY_LOCATIONID = gql`
  query getDoorAccessCodeByLocationId($locationId: ID!, $nextToken: String) {
    getDoorAccessCodeByLocationId(
      locationId: $locationId
      nextToken: $nextToken
    ) {
      items {
        id
        doorName
        code
        locationId
      }
      nextToken
    }
  }
`;

export const GET_DOOR_ACCESS_CODE_BY_ID = gql`
  query getDoorAccessCode($id: ID!) {
    getDoorAccessCode(id: $id) {
      id
      doorName
      code
      locationId
      instruction
    }
  }
`;

export const GET_MEMBER_BY_TYPE_FOR_LOCATION = gql`
  query getMemberByType($filters: ModelMemberFilterInput, $nextToken: String) {
    getMemberByType(
      filter: $filters
      type: STAFF
      limit: 1000
      nextToken: $nextToken
    ) {
      items {
        memberId
        givenName
        surname
        aliasMemberId
      }
      nextToken
    }
  }
`;

export const GET_LOGGEDUSER_BY_MEMBERID = gql`
  query getMember($memberId: ID!) {
    getMember(memberId: $memberId) {
      memberId
      imageUrl
      givenName
      middleName
      surname
      dob
      gender
      mobileNumber
      email
      secondaryEmail
      joinedDateTime
      address
      suburb
      state
      postCode
      country
      staffKind
      role
      roleDetails {
        items {
          id
          roleName
          description
          haspermissions {
            items {
              permissionsList {
                items {
                  permissionName
                }
              }
            }
          }
        }
      }
      aliasMemberId

      brandId
      homeLocationId
      brandDetails {
        id
        name
      }
      homeLocationDetails {
        timezone
        openDate
        id
        name
      }
      groups {
        items {
          groupDetails {
            groupLocation(limit: 10000) {
              items {
                locationDetails {
                  openDate
                  timezone
                  state
                  status
                  id
                  locationType
                  status
                  name
                  brandDetails {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_BY_ID = gql`
  query getMember($memberId: ID!, $activityNextToken: String) {
    getMember(memberId: $memberId) {
      staffLocationAccess {
        items {
          id
          locationId
          locationDetails {
            brandId
            name
          }
        }
      }
      memberActivity(sortDirection: ASC, nextToken: $activityNextToken) {
        items {
          eventType
          createdAt
        }
        nextToken
      }
      outstandingBalance
      isJuniorMember
      memberId
      imageUrl
      aliasMemberId
      email
      secondaryEmail
      title
      givenName
      middleName
      surname
      gender
      dob
      mobileNumber
      workPhone
      homePhone
      emergencyContactName
      emergencyContactEmail
      emergencyContactNumber
      emergencyContactRelation
      emergencyContactName2
      emergencyContactEmail2
      emergencyContactNumber2
      emergencyContactRelation2
      address
      suburb
      state
      postCode
      country
      joinedDateTime
      getLinksSms
      accepted
      brandId
      type
      role
      staffKind
      isBlocked
      homeLocationId
      groups {
        items {
          groupDetails {
            groupLocation {
              items {
                locationDetails {
                  state
                  id
                  name
                  brandDetails {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }

      brandDetails {
        id
        name
      }
      homeLocationDetails {
        openDate
        id
        name
        timezone
        locationMaintenance {
          items {
            id
            locationId
            startDateTime
            endDateTime
            isDeleted
          }
        }
      }
      contracts {
        items {
          id
          recurring
          isFitnessPassport
          fitnessPassportNumber
          brandDetails {
            id
            name
          }
          juniorMemberconsentFormDetail {
            formUrl
            submitResponseAt
            arequestionsAnswered
            signature
          }
          membershipDetails {
            membershipName
            membershipType
          }
          isPending
          description
          membershipName
          startDateTime
          endDateTime
          expiryDateTime
          locationId
          membershipId
          costPrice
          timezone
          createdAt
          costPriceFromMembership
          joiningFee
          joiningFee2
          joiningFee3
          membershipLocationId
          memberContractUrl
          isFitnessPassport
          fitnessPassportNumber
          contractLength {
            type
            amount
          }
          paymentDelay {
            amount
            type
          }
          paymentFrequency {
            type
            amount
          }
          joinedLocationDetail {
            openDate
            name
            shortName
            timezone
          }
          suspensions {
            items {
              suspensionType
              suspensionStatus
              suspensionStartDateTime
              suspensionEndDateTime
              cancelledDateTime
            }
          }
          billings {
            items {
              reason
              description
              status
              isProcessed
              debitDate
              debitAmount
              currency
              id
              membershipAmount
              description
              locationId
              memberContractId
              memberId
              membershipId
              membershipLocationId
              payLocationId
              paymentType
              outstandingLogDetail {
                transactionId
              }
              directDebitLog {
                items {
                  id
                  billingId
                  updatedBy
                  description
                  debitAmountNew
                  debitAmountOriginal
                  reason
                  createdAt
                  updaterDetails {
                    memberId
                    createdAt
                    givenName
                    surname
                  }
                }
              }
              refundDetails {
                refundStatus
                paymentType
              }
            }
          }
          voucherDetail {
            id
            membershipId
            voucherCode
            startDateTime
            endDateTime
            quantity
            visit
            used
            type
            cost
            isActive
            note
            passFee
            joiningFee
            activationFee
            createdAt
            createdBy
            updatedAt
            duration
            paymentDelay {
              amount
              type
            }
            membershipDetail {
              id
              membershipName
              recurring
              costPrice
              joiningFee
              joiningFee2
              joiningFee3
              description
              contractLength {
                amount
                type
              }
              paymentFrequency {
                type
                amount
              }
              membershipLocations {
                items {
                  id
                  locationId
                }
              }
            }
          }
        }
      }
      paymentDetails {
        items {
          accountNumber
          accountName
          bsb
          cardCvv
          cardExpiryDate
          cardHolderName
          cardNumber
          cardType
          id
          paymentType
          primary
          createdAt
          cardToken
          vivaPaymentInformationId
        }
      }
      memberBlockDetails {
        items {
          isThisBlockActive

          blockedBy
        }
      }
      DDFailureCount
    }
  }
`;

export const GET_MEMBER_ACTIVITY_BY_MEMBERID = gql`
  query getMemberActivityByMemberId(
    $memberId: ID!
    $filters: ModelMemberActivityFilterInput
    $createdAt: ModelStringKeyConditionInput
    $nextToken: String
  ) {
    getMemberActivityByMemberId(
      memberId: $memberId
      filter: $filters
      createdAt: $createdAt
      nextToken: $nextToken
      sortDirection: DESC
    ) {
      items {
        id
        email
        eventType
        createdAt
        dateTime
      }
      nextToken
    }
  }
`;

export const GET_NOTES_BY_MEMBERID = gql`
  query notesByMemberId(
    $memberId: ID!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelNoteFilterInput
    $nextToken: String
  ) {
    notesByMemberId(
      memberId: $memberId
      createdAt: $createdAt
      filter: $filter
      nextToken: $nextToken
      sortDirection: DESC
    ) {
      items {
        id
        note
        createdAt
        addedBy
        memberId
      }
      nextToken
    }
  }
`;

export const GET_CLASSBOOKINGS_BY_MEMBERID = gql`
  query getClassBookingsByMemberId(
    $memberId: ID!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelClassBookingFilterInput
    $nextToken: String
  ) {
    getClassBookingsByMemberId(
      memberId: $memberId
      createdAt: $createdAt
      filter: $filter
      nextToken: $nextToken
      sortDirection: DESC
    ) {
      items {
        createdAt

        locationDetails {
          name
        }
        classScheduleDetails {
          isActive
          classScheduleDateTime
          instructorDetails {
            givenName
            surname
          }
        }
        classTemplateDetails {
          className
        }
        locationDetails {
          timezone
        }
      }
      nextToken
    }
  }
`;
export const GET_MEMBER_ACCESS_LOGS_BY_MEMBER_ID = gql`
  query getAccessLogByMemberId(
    $memberId: ID!
    $filter: ModelDoorAccessLogFilterInput
    $createdAt: ModelStringKeyConditionInput
    $nextToken: String
  ) {
    getAccessLogByMemberId(
      memberId: $memberId
      filter: $filter
      createdAt: $createdAt
      nextToken: $nextToken
      sortDirection: DESC
    ) {
      items {
        swipePhoto
        memberPhoto
        processLocationTime
        isOnline
        createdAt
        isValid
        memberHomeClub
        deviceType
        doorDetails {
          id
          doorName
          accessPointname
          accessPoint
        }
        memberContract {
          membershipName
        }
        locationDetails {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const GET_REFUNDS_BY_MEMBER_ID = gql`
  query getRefundTransactionsByMemberId(
    $memberId: ID!
    $createdAt: ModelStringKeyConditionInput
    $nextToken: String
  ) {
    getRefundTransactionsByMemberId(
      memberId: $memberId
      createdAt: $createdAt
      nextToken: $nextToken
      sortDirection: DESC
    ) {
      items {
        refundDate
        refundBy
        refundType
        refundStatus
        refundAmount
        paymentType
        locationDetails {
          name
        }
        refundByDetails {
          surname
          givenName
        }
        requestByDetails {
          surname
          givenName
        }
        updatedAt
      }
    }
  }
`;
export const GET_MEMBER_SUSPENSIONS_BY_MEMBER_ID = gql`
  query getSuspensionByMemberId(
    $memberId: ID!
    $createdAt: ModelStringKeyConditionInput
    $nextToken: String
  ) {
    getSuspensionByMemberId(
      memberId: $memberId
      createdAt: $createdAt
      nextToken: $nextToken
      sortDirection: DESC
    ) {
      items {
        id
        memberId
        memberDetails {
          givenName
          surname
        }
        suspensionReason
        suspensionStartDateTime
        suspensionEndDateTime
        suspensionStatus
        suspensionType
        createdAt
        contractDetails {
          membershipName
          createdAt
        }
        cancelledDateTime
      }
      nextToken
    }
  }
`;
export const GET_SMS_BY_MEMBERID = gql`
  query getSmsOutboxByMemberId(
    $memberId: ID!
    $createdAt: ModelStringKeyConditionInput
    $filters: ModelSmsOutboxFilterInput
    $nextToken: String
  ) {
    getSmsOutboxByMemberId(
      memberId: $memberId
      createdAt: $createdAt
      filter: $filters
      sortDirection: DESC
      nextToken: $nextToken
    ) {
      items {
        id
        to
        message
        from
        locationId
        memberId
        identityName
        messageId
        outGoingId
        status
        sentDateTime
        locationDetails {
          name
        }
        createdAt
      }
      nextToken
    }
  }
`;
export const GET_SMS_MESSAGE = gql`
  query searchSmsIncomings(
    $filter: SearchableSmsIncomingFilterInput
    $nextToken: String
  ) {
    searchSmsIncomings(
      filter: $filter
      nextToken: $nextToken
      sort: [{ field: dateTime, direction: desc }]
    ) {
      items {
        id
        from
        to
        message
        memberDetails {
          surname
          givenName
        }
        locationDetails {
          name
        }
        createdAt
        dateTime
      }
      nextToken
    }
  }
`;
export const GET_AUDITVISITLOG_BY_MEMBERID = gql`
  query getAuditVisitLogByMemberId(
    $memberId: ID!
    $filters: ModelAuditVisitLogFilterInput
    $createdAt: ModelStringKeyConditionInput
    $nextToken: String
  ) {
    getAuditVisitLogByMemberId(
      memberId: $memberId
      filter: $filters
      createdAt: $createdAt
      nextToken: $nextToken
      sortDirection: DESC
    ) {
      items {
        id
        memberDetails {
          givenName
          middleName
          surname
        }
        clickedByDetails {
          givenName
          middleName
          surname
        }
        createdAt
        whatwasDone
      }
      nextToken
    }
  }
`;

export const GET_AUDITOPERATION_BY_MEMBERID = gql`
  query getAuditOperationByMemberId(
    $memberId: ID!
    # $createdAt: ModelStringKeyConditionInput
    # $filter: ModelAuditOperationFilterInput
    $nextToken: String
  ) {
    getAuditOperationByMemberId(
      memberId: $memberId
      # createdAt: $createdAt
      # filter: $filters
      nextToken: $nextToken
      sortDirection: DESC
    ) {
      items {
        id
        oldAttributeName
        email
        updatedBy
        createdAt
        action
        endpoint
        updaterDetails {
          givenName
          surname
        }
      }
      nextToken
    }
  }
`;

// export const GET_MEMEBER_INTERACTION = gql`
//   query getMember($memberId: ID!, $nextnToken: String) {
//     getMember(memberId: $memberId) {
//       memberId
//       createdAt
//       aliasMemberId
//       type
//       email
//       memberActivity(limit: 2, sortDirection: ASC, nextToken: $nextnToken) {
//         items {
//           eventType
//           createdAt
//         }
//         nextToken
//       }
//     }
//   }
// `;

export const GET_NOTE = gql`
  query getNote($id: ID!) {
    getNote(id: $id) {
      id
      memberId
      note
      addedBy
      createdAt
      memberDetails {
        givenName
        middleName
        surname
      }
      addedByDetails {
        givenName
        middleName
        surname
      }
    }
  }
`;

export const GET_MEMBER_ACTIVITY = gql`
  query getMemberActivity($id: ID!) {
    getMemberActivity(id: $id) {
      email
      eventType
      createdAt
      memberId
      memberDetails {
        givenName
        middleName
        surname
      }
    }
  }
`;

export const GET_AUDIT_VISIT_LOG = gql`
  query getAuditVisitLog($id: ID!) {
    getAuditVisitLog(id: $id) {
      clickedByDetails {
        givenName
        middleName
        surname
      }
      memberDetails {
        givenName
        middleName
        surname
      }
      whatwasDone
      createdAt
    }
  }
`;

export const GET_SMS = gql`
  query getSmsOutbox($id: ID!) {
    getSmsOutbox(id: $id) {
      createdAt
      locationId
      locationDetails {
        name
      }
      status
      message
    }
  }
`;

export const LIST_CLASS_TEMPLATE = gql`
  query listClassTemplates(
    $filter: ModelClassTemplateFilterInput
    $nextToken: String
  ) {
    listClassTemplates(filter: $filter, nextToken: $nextToken) {
      items {
        id
        isActive
        hasVirtualClass
        isBookable
        isDeleted
        hasWaitList
        brandId
        brandDetails {
          name
        }
        className
        classDuration
        daysWithin
        maxMembers
        classDescription
      }
      nextToken
    }
  }
`;

export const LIST_BRAND = gql`
  query listBrands {
    listBrands {
      items {
        id
        name
        description
        locations {
          items {
            id
            name
          }
        }
      }
      nextToken
    }
  }
`;
export const LIST_REJECTIONS = gql`
  query listRejectionProcesses($nextToken: String) {
    listRejectionProcesses(nextToken: $nextToken) {
      items {
        id
        fileName
        isProcessed
        s3Key
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_AUDIT_OPERATION = gql`
  query getAuditOperation($id: ID!) {
    getAuditOperation(id: $id) {
      oldAttributeName
      oldAttributeValue
      newAttributeName
      newAttributeValue
      createdAt
      endpoint
      action
      updaterDetails {
        givenName
        middleName
        surname
      }
      memberId
    }
  }
`;
export const GET_GUEST_ENTRY_LOG_BY_MEMBER_ID = gql`
  query getGuestEntryByMemberId(
    $memberId: ID!
    $filter: ModelGuestEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGuestEntryByMemberId(
      memberId: $memberId
      sortDirection: DESC
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        locationDetails {
          name
        }
        firstName
        lastName
        contactNumber
        reasonForEntry
        createdAt
        staffDetails {
          givenName
          surname
        }
      }
      nextToken
    }
  }
`;

export const GET_LOCATION_BY_BRANDID = gql`
  query getLocationByBrandId(
    $brandId: ID!
    $membershipFilter: ModelMembershipLocationFilterInput
    $nextToken: String
    $limit: Int
    $membershipLimit: Int
  ) {
    getLocationByBrandId(
      brandId: $brandId
      nextToken: $nextToken
      filter: { status: { eq: ACTIVE } }
      limit: $limit
    ) {
      items {
        id
        name
        shortName
        status
        status
        memberships(filter: $membershipFilter, limit: $membershipLimit) {
          items {
            membershipId
            locationId
            costPrice
            isActive
            membershipDetails {
              membershipName
            }
            locationDetails {
              timezone
              name
            }
          }
        }
        brandDetails {
          name
        }
      }
      nextToken
    }
  }
`;

export const GET_BATCHPAYMENT_BY_BATCHID = gql`
  query getBatchPaymentByBatchBrandId(
    $batchBrandId: String!
    $paymentType: ModelStringKeyConditionInput
    $nextToken: String
  ) {
    getBatchPaymentByBatchBrandId(
      batchBrandId: $batchBrandId
      paymentType: $paymentType
      limit: 1
      nextToken: $nextToken
    ) {
      items {
        memberId
        batchId
        batchBrandId
        locationId
        uniqueIdentifier
        paymentType
        amount
        paymentDetails(
          filter: { paymentType: { eq: DIRECT_DEBIT }, primary: { eq: true } }
        ) {
          items {
            bsb
            cardHolderName
            cardType
            accountName
            accountNumber
          }
        }
        locationDetails {
          brandId
          billerCode
        }
      }
      nextToken
    }
  }
`;
export const GENERATE_ABA_BY_BRAND = gql`
  query generateABA(
    $action: String!
    $brandId: String!
    $endDate: AWSDate
    $batchBrandId: String
    $memberIds: [ID!]
  ) {
    generateABA(
      action: $action
      brandId: $brandId
      endDate: $endDate
      batchBrandId: $batchBrandId
      memberIds: $memberIds
    ) {
      statusCode
      message
      url
    }
  }
`;
export const LIST_REJECTION_PAYMENT = gql`
  query listRejectionPayments(
    $limit: Int
    $nextToken: String
    $filter: ModelRejectionPaymentFilterInput
  ) {
    listRejectionPayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        memberId
        locationId
        debitDate
        amount
        status
      }
    }
  }
`;
export const LIST_BATCHPAYMENT = gql`
  query listBatchPayments($filter: ModelBatchPaymentFilterInput, $limit: Int) {
    listBatchPayments(filter: $filter, limit: $limit) {
      items {
        memberId
        locationId
        debitDate
        amount
        status
        memberDetails {
          createdAt
          memberId
          aliasMemberId
          email
          createdAt
          secondaryEmail
          title
          givenName
          middleName
          surname
          gender
          dob
          mobileNumber
          address
          suburb
          state
          postCode
          country
          joinedDateTime
          homeLocationId
          joinLocationId
          type
          role
          outstandingBalance
          isBlocked
          createdAt
          updatedAt
          joinLocationId
          brandDetails {
            name
          }
          memberBlockDetails {
            items {
              blockFrom
              blockTo
            }
          }
          contracts {
            items {
              isActive
              expiryDateTime
              startDateTime
              membershipName
              endDateTime
              paymentFrequency {
                type
                amount
              }
              billings {
                items {
                  debitAmount
                  isProcessed
                }
              }
              suspensions {
                items {
                  suspensionStartDateTime
                  suspensionEndDateTime
                  cancelledDateTime
                  suspensionStatus
                  suspensionType
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const GET_LOCTAION_BY_ID = gql`
  query getLocation($id: ID!) {
    getLocation(id: $id) {
      id
      originNumber
      summary
      clubImage
      aliasId
      email
      openDate
      clubUrl
      name
      shortName
      locationType
      phoneNumber
      originNumber
      isClubOpen
      checkOpenTimes
      settlementEmail
      clubPageUrl
      is24Hour
      hasGroupFitness
      ddDescriptorCreditCard
      ddDescriptorBankAccount
      checkClassBookings
      changePriceEmail
      walk3DURL
      timeTableOverrideURL
      membershipTarget
      ptTargetRevenue
      ptTarget
      phoneExtension
      ptPackEmail
      salesExpectedOpeningDate
      isPreSaleOn
      isHiddenOnline
      isFreeTrialApp
      freeTrialLengthDays
      trialVisitLimit
      showLeadSection
      leadsHeader
      mailChimpCode
      leadsEmailAddress
      leadsText
      gymSalesId
      gymSalesHiitId
      keepMeId
      peakLoad
      restrictedLoad
      closeDate
      address1
      state
      city
      country
      postCode
      suburb
      assistantManagerId
      areaManagerId
      is24Hour
      accountName
      accountNumber
      bsb
      abn
      timezone
      locationAccessTimes {
        items {
          id
          day
          openTime
          closeTime
          type
        }
      }
      memberships {
        items {
          id
          locationId
          membershipDetails {
            id
            membershipName
            costPrice
            paymentFrequency {
              amount
              type
            }
            contractLength {
              amount
              type
            }
          }
        }
      }
      geoLocation {
        latitude
        longitude
      }
      brandId
      managerId
      managerDetails {
        givenName
        middleName
        surname
      }
      status
      sapAccountCode
      franchiseOwner
      hasCreche
      accessPoints {
        items {
          id
          accessPointname
          doorName
          swipeMessageText
          errorMessage
          messageFrequency
          isMessageActive
          locationId
          accessPointTimes {
            items {
              id
              closeTime
              day
              openTime
            }
          }
        }
      }
      brandDetails {
        id
        name
      }
      pinCodes {
        items {
          id
          doorName
        }
      }
      studios {
        items {
          id
          studioName
          isActive
        }
      }
      locationFeatures {
        items {
          id
          isAvailable
          featureDetails {
            imageUrl
            id
            name
            description
          }
        }
      }
      locationMaintenance {
        items {
          id
          locationId
          startDateTime
          endDateTime
          isDeleted
        }
      }
      debtCollectionType
    }
  }
`;

export const GET_CLASS_TEMPLATE_BY_BRANDID = gql`
  query getClassTemplateByBrandId(
    $brandId: ID!
    $sortDirection: ModelSortDirection
    $nextToken: String
    $filter: ModelClassTemplateFilterInput
    $limit: Int
  ) {
    getClassTemplateByBrandId(
      brandId: $brandId
      sortDirection: $sortDirection
      nextToken: $nextToken
      filter: $filter
      limit: $limit
    ) {
      items {
        id
        className
        classDuration
        createdAt
        daysWithin
        hasVirtualClass
        isActive
        isDeleted
        id
        maxMembers
        classDescription
        brandDetails {
          name
        }
      }
      nextToken
    }
  }
`;

export const GET_CLASS_TEMPLATE_BY_ID = gql`
  query getClassTemplate($id: ID!) {
    getClassTemplate(id: $id) {
      id
      isActive
      hasVirtualClass
      brandId
      className
      classDuration
      daysWithin
      maxMembers
      classDescription
      brandDetails {
        name
      }
    }
  }
`;
export const GET_CLASS_BOOKINGS = gql`
  query getClassBookingsByClassScheduleId(
    $classScheduleId: ID!
    $nextToken: String
    $limit: Int
  ) {
    getClassBookingsByClassScheduleId(
      classScheduleId: $classScheduleId
      nextToken: $nextToken
      limit: $limit
    ) {
      items {
        hasAttended
        id
        memberId
        cancelledDateTime
        casualFirstName
        casualLastName
        casualEmail
        casualPhone
        memberDetails {
          surname
          givenName
          mobileNumber
          email
        }
      }
      nextToken
    }
  }
`;
export const GET_MEMBER_BY_MOBILE = gql`
  query getMemberByMobile($mobileNumber: AWSPhone!) {
    getMemberByMobile(mobileNumber: $mobileNumber) {
      items {
        memberId
        surname
        givenName
        mobileNumber
      }
    }
  }
`;
export const GET_MEMBER_BY_EMAIL = gql`
  query getMemberByEmail($email: AWSEmail!) {
    getMemberByEmail(email: $email) {
      items {
        memberId
        surname
        givenName
        mobileNumber
        email
      }
    }
  }
`;
export const GET_MEMBER_CONTRACT_LOG_BY_MEMBERID = gql`
  query getMemberContractLogByMemberId(
    $memberId: ID!
    $filter: ModelMemberContractLogFilterInput
    $logType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    getMemberContractLogByMemberId(
      memberId: $memberId
      logType: $logType
      filter: $filter
      sortDirection: $sortDirection
    ) {
      items {
        id
        logType
        logDescription
        newMembershipAmount
        oldMembershipAmount
        reason
        description
        updatedBy
        createdAt
        updaterDetails {
          givenName
          surname
        }
        memberContractId
        memberContractDetails {
          description
        }
      }
      nextToken
    }
  }
`;

export const GET_CLASSSCHEDULE_BY_LOCATIONID = gql`
  query getClassScheduleByLocationId(
    $locationId: ID!
    $classScheduleDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    getClassScheduleByLocationId(
      locationId: $locationId
      classScheduleDateTime: $classScheduleDateTime
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationId
        bookingMessage
        classScheduleEndDateTime
        classScheduleDateTime
        createdAt
        classTemplateId
        isActive
        isBookable
        maxBooking
        isThisSeries
        bookings {
          items {
            id
            cancelledDateTime
          }
        }
        classTemplateDetails {
          id
          className
          brandId
          classDuration
        }
        instructorDetails {
          id
          givenName
          surname
          instructorFullName
          instructorDisplayName
        }
        studioDetails {
          id
          studioName
        }
        locationDetails {
          state
          timezone
        }
      }
      nextToken
    }
  }
`;

export const GET_CLASS_SCHEDULE_BY_ID = gql`
  query getClassSchedule($id: ID!) {
    getClassSchedule(id: $id) {
      id
      imageUrl
      isBookable
      canWaitlist
      classTemplateId
      locationId
      studioId
      isThisRepeated
      instructorId
      isThisSeries
      seriesId
      bookingLockoutMinutes
      cancelLockoutMinutes
      maxBooking
      bookingCount
      targetBooking
      maxTeam
      maxPerTeam
      note
      classScheduleDateTime
      classScheduleEndDateTime
      instructorDetails {
        instructorDisplayName
        givenName
        surname
      }
      studioDetails {
        studioName
      }
      classTemplateDetails {
        className
        brandId
        classDuration
      }
      bookingMessage
    }
  }
`;
export const GET_STUDIO_BY_LOCATION_ID = gql`
  query getStudioByLocationId($locationId: ID!) {
    getStudioByLocationId(locationId: $locationId) {
      items {
        id
        locationId
        studioName
        isActive
      }
    }
  }
`;

export const GET_ALL_INSTRUCTOR = gql`
  query listClassInstructors($limit: Int, $nextToken: String) {
    listClassInstructors(limit: $limit, nextToken: $nextToken) {
      items {
        id
        instructorFullName
        instructorDisplayName
        givenName
        surname
      }
      nextToken
    }
  }
`;
export const SEARCHMEMBER = gql`
  query searchMembers(
    $filter: SearchableMemberFilterInput
    $nextToken: String
    $limit: Int
    $sort: [SearchableMemberSortInput]
  ) {
    searchMembers(
      filter: $filter
      nextToken: $nextToken
      limit: $limit
      sort: $sort
    ) {
      items {
        createdAt
        memberId
        aliasMemberId
        email
        createdAt
        secondaryEmail
        title
        givenName
        middleName
        surname
        gender
        dob
        mobileNumber
        address
        suburb
        state
        postCode
        country
        joinedDateTime
        homeLocationId
        joinLocationId
        type
        role
        outstandingBalance
        isBlocked
        createdAt
        updatedAt
        joinLocationId
        brandDetails {
          name
        }
        memberBlockDetails {
          items {
            blockFrom
            blockTo
          }
        }
        contracts {
          items {
            isActive
            expiryDateTime
            startDateTime
            membershipName
            endDateTime
            paymentFrequency {
              type
              amount
            }
            billings {
              items {
                debitAmount
                isProcessed
              }
            }
            suspensions {
              items {
                suspensionStartDateTime
                suspensionEndDateTime
                cancelledDateTime
                suspensionStatus
                suspensionType
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const LIST_MEMBERS = gql`
  query listMembers(
    $filter: ModelMemberFilterInput
    $nextToken: String
    $limit: Int
    $contractFilter: ModelMemberContractFilterInput
  ) {
    listMembers(filter: $filter, nextToken: $nextToken, limit: $limit) {
      items {
        isLoginEnabled
        createdAt
        memberId
        aliasMemberId
        email
        createdAt
        secondaryEmail
        title
        givenName
        middleName
        surname
        gender
        dob
        mobileNumber
        address
        suburb
        state
        postCode
        country
        joinedDateTime
        homeLocationId
        joinLocationId
        type
        role
        outstandingBalance
        isBlocked
        createdAt
        updatedAt
        joinLocationId
        brandDetails {
          name
        }
        homeLocationDetails {
          name
        }
        contracts(filter: $contractFilter) {
          items {
            id
            startDateTime
            isActive
            expiryDateTime
            startDateTime
            membershipName
            endDateTime
            isPending
            paymentFrequency {
              type
              amount
            }
            juniorMemberconsentFormDetail {
              signature
              submitResponseAt
            }
            billings {
              items {
                # debitDate
                debitAmount
                isProcessed
                BillingCredit {
                  items {
                    amount
                  }
                }
              }
            }
            suspensions {
              items {
                suspensionStartDateTime
                suspensionEndDateTime
                cancelledDateTime
                suspensionStatus
                suspensionType
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const LIST_MEMBER_BLOCKS = gql`
  query listMemberBlocks(
    $filter: ModelMemberBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        memberDetails {
          brandDetails {
            name
          }
          contracts {
            items {
              expiryDateTime
              startDateTime
              membershipName
              endDateTime
              paymentFrequency {
                type
                amount
              }
              billings {
                items {
                  debitDate
                  debitAmount
                }
              }
              suspensions {
                items {
                  suspensionStartDateTime
                  suspensionEndDateTime
                }
              }
            }
          }
          memberId
          aliasMemberId
          email
          createdAt
          secondaryEmail
          title
          givenName
          middleName
          surname
          gender
          dob
          mobileNumber
          address
          suburb
          state
          postCode
          country

          homeLocationId
          joinLocationId
          type
          role
          isBlocked
          createdAt
          updatedAt
          joinLocationId
          memberBlockDetails {
            items {
              blockFrom
              blockTo
            }
          }
        }
      }
      nextToken
    }
  }
`;

// export const LIST_MEMBER_CONTRACTS_=gql`listMemberContracts(
//   filter: {
//   or:[
//         {endDateTime: { lt: "2024-01-18T00:00:00.000Z" }}
//         { expiryDateTime: { lt: "2024-01-18T00:00:00.000Z" }}
//         ]
//         locationId:{eq:"f5403e3b-4dcc-4611-9b60-a639b80ddf11"}
//     }
// )`

export const LIST_MEMBERSHIPS = gql`
  query ListMemberships(
    $id: ID
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMemberships(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        brandId
        id
        isActive
        membershipName
        recurring
        costPrice
        joiningFee
        joiningFee2
        joiningFee3
        isPeak
        contractLength {
          type
        }
        isLiveOnWebsite
        createdAt
        isDeleted
        description
        paymentDelay {
          amount
          type
        }
        brandDetails {
          name
        }
        paymentFrequency {
          type
          amount
        }
        contractLength {
          type
          amount
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_MEMBERSHIP_TYPE_BY_ID = gql`
  query getMembership($id: ID!) {
    getMembership(id: $id) {
      id
      membershipType
      membershipLevel
      costPrice
      description
      id
      isActive
      recurring
      isLiveOnWebsite
      isPeak
      joiningFee
      joiningFee2
      joiningFee3
      membershipName
      contractLength {
        amount
        type
      }
      paymentType {
        items {
          name
          isActive
        }
      }
      paymentDelay {
        amount
        type
      }
      paymentFrequency {
        amount
        type
      }
      noticePeriod {
        amount
        type
      }
      activationDates(limit: 70) {
        items {
          id
          startDateTime
          endDateTime
          isActive
        }
      }
      brandId
      brandDetails {
        name
      }
      membershipLocations(limit: 70) {
        items {
          id
          locationId
          costPrice
          joiningFee
          fobFee
          activationFee
          isActive
          locationDetails {
            name
            memberships {
              items {
                membershipDetails {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const LIST_MEMBERSHIP_LOCATIONS = gql`
  query listMembershipLocations {
    listMembershipLocations {
      items {
        id
        costPrice
        locationDetails {
          name
          status
          brandDetails {
            name
          }
        }
      }
    }
  }
`;

export const SEARCH_MEMBER_CONTRACTS_BY_MEMBERID = gql`
  query SearchMemberContracts {
    searchMemberContracts(
      filter: { memberId: { eq: "18f3c646-921a-5880-de7b-5026c8accf2a" } }
    ) {
      items {
        membershipName
        billings {
          items {
            membershipAmount
            directDebitLog {
              items {
                updaterDetails {
                  givenName
                  middleName
                  surname
                }
                createdAt
                reason
                debitAmountOriginal
                debitAmountNew
                description
                billingId
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_MEMBERSHIP_BY_LOCATION_ID = gql`
  query getLocation($id: ID!) {
    getLocation(id: $id) {
      id
      memberships {
        items {
          membershipDetails {
            id
            membershipName
            isActive
            isDeleted
            membershipLocations {
              items {
                id
                locationId
                isActive
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_10_LATEST_MEMBER = gql`
  query getMemberByType(
    $type: MEMBER_TYPE!
    $joinDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $nextToken: String
  ) {
    getMemberByType(
      type: $type
      joinDate: $joinDate
      sortDirection: $sortDirection
      filter: $filter
      limit: 10
      nextToken: $nextToken
    ) {
      items {
        memberId
        dob
        role
        memberId
        type
        email
        givenName
        surname
        mobileNumber

        createdAt
        homeLocationDetails {
          name
        }
        brandDetails {
          name
        }
      }
      nextToken
    }
  }
`;

export const LIST_DIRECT_DEBIT_LOGS = gql`
  query listDirectDebitLogs {
    listDirectDebitLogs {
      items {
        id
        billingId
        updatedBy
        debitAmountOriginal
        debitAmountNew
        reason
        description
        createdAt
        updaterDetails {
          surname
          givenName
        }
      }
    }
  }
`;
export const SEARCH_MEMBER_PENDING = gql`
  query searchMembersPending(
    $filter: SearchableMemberFilterInput
    $nextToken: String
    $limit: Int
    $sort: [SearchableMemberSortInput]
    $contractFilter: ModelMemberContractFilterInput
  ) {
    searchMembers(
      filter: $filter
      nextToken: $nextToken
      limit: $limit
      sort: $sort
    ) {
      items {
        memberId
        accepted
        dob
        role
        memberId
        type
        email
        givenName
        surname
        mobileNumber
        joinedDateTime
        state
        accepted

        createdAt
        isJuniorMember
        paymentDetails {
          items {
            id
          }
        }
        homeLocationDetails {
          name
        }
        brandDetails {
          name
        }
        contracts(filter: $contractFilter) {
          items {
            id
            paymentDelay {
              amount
              type
            }
            juniorMemberconsentFormDetail {
              id
              signature
              consentFormInviteTokenExpiry
            }
            joiningFee
            joiningFee2
            joiningFee3
            costPrice
            startDateTime
            isPending
          }
        }
      }
      nextToken
    }
  }
`;
export const SEARCH_MEMBER = gql`
  query searchMembers(
    $filter: SearchableMemberFilterInput
    $nextToken: String
    $limit: Int
    $sort: [SearchableMemberSortInput]
  ) {
    searchMembers(
      filter: $filter
      nextToken: $nextToken
      limit: $limit
      sort: $sort
    ) {
      items {
        memberId
        accepted
        dob
        role
        memberId
        type
        email
        givenName
        surname
        mobileNumber
        aliasMemberId
        joinedDateTime
        state
        accepted
        isActive
        createdAt
        homeLocationId
        homeLocationDetails {
          id
          name
        }
        brandDetails {
          name
        }
        contracts {
          items {
            id
            isPending
            startDateTime
            endDateTime
            expiryDateTime
            createdAt
            isFitnessPassport
            fitnessPassportNumber
            suspensions {
              items {
                suspensionType
                suspensionStatus
                suspensionStartDateTime
                suspensionEndDateTime
                cancelledDateTime
              }
            }
            membershipDetails {
              membershipType
              membershipName
            }
            membershipId
            locationId
            membershipLocationId
            membershipName
            recurring
            voucherDetail {
              id
              isActive
            }
          }
        }
        paymentDetails {
          items {
            id
            cardNumber
            primary
            paymentType
            cardType
            cardExpiryDate
            vivaPaymentInformationId
          }
        }
        voucherMembers {
          items {
            voucherId
            status
          }
        }
      }
      nextToken
    }
  }
`;

export const LIST_MEMBER_CONTRACT = gql`
  query listMemberContracts(
    $id: ID
    $billingsnextToken: String
    $billingslimit: Int
  ) {
    listMemberContracts(id: $id) {
      items {
        id
        recurring
        paymentFrequency {
          type
          amount
        }

        billings(nextToken: $billingsnextToken, limit: $billingslimit) {
          items {
            refundTransactionId
            refundDetails {
              refundStatus
              paymentType
            }
            status
            isProcessed
            paymentType
            debitDate
            membershipAmount
            memberContractId
            debitAmount
            rejectionFee
            actualDebitAmount
            timezone
            transactionId
            outstandingLogDetail {
              transactionId
            }
            id
            paymentDate
            settlementDate
            reason
            description
            directDebitLog {
              items {
                createdAt
                reason
                description
                debitAmountNew
                debitAmountOriginal
                updaterDetails {
                  givenName
                  surname
                }
              }
            }
            BillingCredit {
              items {
                amount
                createdAt
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;

export const LIST_CLASS_INSTRUCTORS = gql`
  query listClassInstructors($limit: Int, $nextToken: String) {
    listClassInstructors(limit: $limit, nextToken: $nextToken) {
      items {
        id
        givenName
        surname
        instructorFullName
        instructorDisplayName
        instructorEmail
        instructorMobileNumber
        isInstructorActive
        createdAt
        updatedAt
        dob
      }
      nextToken
    }
  }
`;

export const GET_CLASS_INSTRUCTOR = gql`
  query getClassInstructor($id: ID!) {
    getClassInstructor(id: $id) {
      id
      title
      instructorDisplayName
      givenName
      surname
      instructorEmail
      instructorMobileNumber
      gender
      dob
      address
      suburb
      state
      country
      postCode
      description
      instructorCredentialList {
        items {
          credentialName
          credentialType
          id
          imageUrl
          instructorId
          isDeleted
          createdAt
          credentialDescription
          updatedAt
        }
      }
    }
  }
`;
export const GET_STUDIO_BY_ID = gql`
  query getLocationStudio($id: ID!) {
    getLocationStudio(id: $id) {
      studioName
      id
      description
      isActive
    }
  }
`;

export const GET_MEMBER_CONTRACT_BY_CONTRACTID = gql`
  query getMemberContract($id: ID!) {
    getMemberContract(id: $id) {
      memberId
      isActive
      expiryDateTime
      endDateTime
      startDateTime
      locationId
      recurring
      contractLength {
        amount
        # endDateTime
        type
      }
      timezone
      paymentFrequency {
        amount
        type
      }
      billings {
        items {
          debitDate
          debitAmount
        }
      }
      suspensions {
        items {
          id
          suspensionStatus
          suspensionStartDateTime
          memberContractId
          suspensionReason
          suspensionEndDateTime

          cancelledDateTime
          suspensionType
        }
      }
    }
  }
`;

export const GET_ROLE_BY_ROLE_NAME = gql`
  query getRoleByRoleName($roleName: String!) {
    getRoleByRoleName(roleName: $roleName) {
      items {
        roleName
        haspermissions {
          items {
            permissionsList {
              items {
                permissionName
              }
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_MEMBERCONTRACT_SUSPENSIONS = gql`
  query searchMemberContractSuspensions(
    $filter: SearchableMemberContractSuspensionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchMemberContractSuspensions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberId
        memberContractId
        suspensionType
        suspensionStartDateTime
        suspensionEndDateTime
        suspensionStatus
        memberDetails {
          email
          aliasMemberId
          createdAt
          givenName
          surname
          dob
          mobileNumber

          type
          brandDetails {
            name
          }
        }
        contractDetails {
          membershipName
          paymentFrequency {
            type
            amount
          }
          billings {
            items {
              debitAmount
              debitDate
              BillingCredit {
                items {
                  amount
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const GET_AUDIT_VISIT_LOG_CLICKED_BY = gql`
  query getAuditVisitLogByClickedBy(
    $clickedBy: ID!
    $createdAt: ModelStringKeyConditionInput
    $nextToken: String
  ) {
    getAuditVisitLogByClickedBy(
      clickedBy: $clickedBy
      createdAt: $createdAt
      nextToken: $nextToken
      sortDirection: DESC
    ) {
      items {
        userName
        whatwasDone
        ipAddress
        createdAt
        clickedByDetails {
          createdAt

          givenName
          surname
        }
        memberDetails {
          givenName
          surname
        }
        locationDetails {
          name
          brandDetails {
            name
          }
        }
        classScheduleDetails {
          isActive
          classScheduleDateTime
          classTemplateDetails {
            className
          }
        }
      }
      nextToken
    }
  }
`;

export const GET_MEMBER_BLOCK_BY_MEMBERID = gql`
  query getMemberBlockByMemberId($memberId: ID!) {
    getMemberBlockByMemberId(memberId: $memberId) {
      items {
        blockFrom
        blockTo
        isThisBlockActive
        reasonsForBlocking
        blockedBy
      }
    }
  }
`;
export const LIST_GROUPS = gql`
  query listGroups($nextToken: String) {
    listGroups(nextToken: $nextToken, limit: 10000) {
      items {
        id
        name
        createdAt
        groupStaff {
          items {
            staffId
            id
          }
        }
        groupLocation {
          items {
            locationId
          }
        }
      }
      nextToken
    }
  }
`;
export const GET_GROUP_BY_STAFFID = gql`
  query GetGroupStaffByStaffId($staffId: ID!) {
    getGroupStaffByStaffId(staffId: $staffId, limit: 10000) {
      items {
        groupId
        groupDetails {
          name
          groupLocation(limit: 10000) {
            items {
              locationDetails {
                id
                name
                brandDetails {
                  name
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_GROUP = gql`
  query getGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      description
      groupLocation(limit: 100000) {
        items {
          id
          locationId
          locationDetails {
            name
            brandDetails {
              name
            }
            state
          }
        }
      }
      groupStaff(limit: 100000) {
        items {
          id
          staffId
          createdAt
          staffDetails {
            givenName
            surname
            middleName
            email
          }
        }
      }
    }
  }
`;

export const GET_WAITINGLIST_CLASS_BOOKING = gql`
  query getWaitlistClassBookingsByClassScheduleId(
    $waitlistClassScheduleId: ID!
    $nextToken: String
    $limit: Int
  ) {
    getWaitlistClassBookingsByClassScheduleId(
      waitlistClassScheduleId: $waitlistClassScheduleId
      nextToken: $nextToken
      limit: $limit
    ) {
      items {
        id

        isCasualBooking
        casualFirstName
        casualLastName
        casualPhone
        cancelledDateTime
        memberDetails {
          memberId
          givenName
          surname
          mobileNumber
        }
      }
      nextToken
    }
  }
`;
export const GET_MEMBER_CONTRACT_SUSPENSION_BY_SUSEPNSIONID = gql`
  query getMemberContractSuspension($id: ID!) {
    getMemberContractSuspension(id: $id) {
      memberId
      suspensionType
      suspensionReason
      suspensionStartDateTime
      suspensionEndDateTime
      suspensionCost
      createdAt
      imageUrl
    }
  }
`;
export const LIST_MEMBER_ACCESS_LOGS = gql`
  query searchDoorAccessLogs(
    $filter: SearchableDoorAccessLogFilterInput
    $sort: [SearchableDoorAccessLogSortInput]
    $limit: Int
    $nextToken: String
  ) {
    searchDoorAccessLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationId
        memberId
        memberName
        swipePhoto
        memberPhoto
        createdAt
        isValid
        memberHomeClub
        deviceType
        cardNumber
        homeLocationId
        doorName
        memberShipName
        processLocationTime
        isPostData
        isOnline
      }
      nextToken
    }
  }
`;
export const LIST_NOTIFICATIONS = gql`
  query listNotifications(
    $limit: Int
    $nextToken: String
    $filter: ModelNotificationFilterInput
    $staffActivitiesFilter: ModelActionNotificationStaffFilterInput
  ) {
    listNotifications(limit: $limit, nextToken: $nextToken, filter: $filter) {
      items {
        id
        memberId
        voucherId
        locationId
        brandId
        cancelDate
        actionByMemberId
        billingId
        contractId
        createdAt
        displayUntil
        email
        eventType
        outstandingBalance
        suspensionStart
        suspensionType
        updatedAt
        staffActivities(filter: $staffActivitiesFilter) {
          items {
            id
            notificationId
            staffMemberId
            action
            createdAt
            updatedAt
          }
        }
        voucherDetails {
          id
          voucherCode
          type
          startDateTime
          endDateTime
          quantity
          cost
        }
        brandDetails {
          id
          name
          description
        }
        locationDetails {
          id
          name
          shortName
        }
        actionByMemberDetails {
          middleName
          givenName
          surname
        }
        memberDetails {
          memberId
          middleName
          givenName
          surname
        }
      }
      nextToken
    }
  }
`;
export const LIST_CARD_NUMBERS = gql`
  query listCardNumbers($filter: ModelCardNumberFilterInput) {
    listCardNumbers(filter: $filter) {
      items {
        id
        cardNumber
        isLost
        startDate
        endDate
        lostReason
        createdAt
        memberId
      }
    }
  }
`;

export const GET_CARD_DETAILS_BY_MEMBERID = gql`
  query getCardDetailsByMemberId($memberId: ID!) {
    getCardDetailsByMemberId(memberId: $memberId) {
      items {
        id
        memberId
        cardNumber
        startDateTime
        endDateTime
        cancelledDateTime
        isLost
        lostReason
        createdAt
      }
    }
  }
`;

export const GET_MEMBERSHIP_TRANSFERS_REPORT = gql`
  query membershipTransfersReport(
    $startDate: AWSDateTime!
    $endDate: AWSDateTime!
  ) {
    membershipTransfersReport(startDate: $startDate, endDate: $endDate) {
      upgradeCount
      downgradeCount
      oldTotal
      newTotal
      difference
      transfers {
        logId
        memberName
        oldMembershipName
        newMembershipName
        logTime
        oldContractCost
        newContractCost
        difference
        updatedById
        newLocationName
        oldLocationName
      }
    }
  }
`;

export const GET_MEMBERSHIP_MOVEMENT_REPORT = gql`
  query getMembershipMovement(
    $firstDate: AWSDateTime!
    $secondDate: AWSDateTime!
    $locationIds: [ID]!
  ) {
    getMembershipMovement(
      action: MEMBERSHIP_MOVEMENT
      firstDate: $firstDate
      locationIds: $locationIds
      secondDate: $secondDate
    ) {
      growth
      movement
      secondDate
      firstDate
      locationDetail {
        state
        name
      }
    }
  }
`;

export const GET_TOTAL_ACTIVE_REPORT = gql`
  query getTotalMemberActive(
    $firstDate: AWSDateTime!
    $locationIds: [ID]!
    $type: MEMBER_TYPE_REPORT!
  ) {
    getTotalMemberActive(
      type: $type
      action: ACTIVE_MEMBERS
      firstDate: $firstDate
      locationIds: $locationIds
    ) {
      date
      totalMember
      locationDetail {
        state
        name
        #regions {
        #  items {
        #    id
        #  }
        #}
      }
    }
  }
`;

export const GET_TOTAL_SUSPENDED_REPORT = gql`
  query getTotalSuspendedMembers(
    $firstDate: AWSDateTime!
    $locationIds: [ID]!
    $suspensionType: SUSPENSION_TYPE_REPORT!
    $monthReport: String!
  ) {
    getTotalSuspendedMembers(
      action: SUSPENDED_MEMBERS
      firstDate: $firstDate
      locationIds: $locationIds
      monthReport: $monthReport
      suspensionType: $suspensionType
    ) {
      totalSuspendedMembers
      totalSuspendedMembersReturning
      locationDetail {
        state
        name
      }
    }
  }
`;

export const GET_TOTAL_SUSPENSION_DETAIL_REPORT = gql`
  query getTotalSuspensionDetailsReport(
    $firstDate: AWSDateTime!
    $locationIds: [ID]!
    $secondDate: AWSDateTime!
    $monthReport: String!
  ) {
    getTotalSuspensionDetailsReport(
      action: SUSPENSION_DETAILS
      firstDate: $firstDate
      secondDate: $secondDate
      locationIds: $locationIds
      monthReport: $monthReport
    ) {
      totalSuspended
      totalSuspensionDates
      totalSuspensionDollar
      totalSuspensionFees
      totalSuspensionReturning
      totalSuspendedMembersReturning
      locationDetail {
        state
        shortName
        name
      }
    }
  }
`;

export const GET_SALES_REPORT = gql`
  query getSalesReport(
    $firstDate: AWSDateTime!
    $secondDate: AWSDateTime!
    $recurring: RECURRING_REPORT!
    $locationIds: [String]!
  ) {
    getSalesReport(
      action: SALES
      firstDate: $firstDate
      locationIds: $locationIds
      recurring: $recurring
      secondDate: $secondDate
    ) {
      totalNewJoiners
      totalReturningMembers
      totalTransfers
      locationDetail {
        state
        shortName
        name
      }
    }
  }
`;

export const GET_TOTAL_MEMBER_VISIT_REPORT = gql`
  query getTotalMemberVisitsReport(
    $firstDate: AWSDateTime!
    $secondDate: AWSDateTime!
    $locationIds: [ID]!
  ) {
    getTotalMemberVisitsReport(
      action: TOTAL_VISITS
      firstDate: $firstDate
      locationIds: $locationIds
      secondDate: $secondDate
    ) {
      totalMemberVisits
      totalUniqueMember
      locationDetail {
        state
        shortName
        name
      }
    }
  }
`;

export const GET_TOTAL_LEAVERS = gql`
  query getMemberLeaversReport(
    $locationIds: [ID]!
    $recurring: RECURRING_REPORT!
    $firstDate: AWSDateTime!
    $secondDate: AWSDateTime!
  ) {
    getMemberLeaversReport(
      action: MEMBER_LEAVERS
      locationIds: $locationIds
      recurring: $recurring
      firstDate: $firstDate
      secondDate: $secondDate
    ) {
      totalMemberLeavers
      locationDetail {
        state
        shortName
        name
      }
    }
  }
`;

export const GET_TOTAL_CHURN = gql`
  query getTotalChurnReport(
    $locationIds: [ID]!
    $recurring: RECURRING_REPORT!
  ) {
    getTotalChurnReport(
      action: CHURN
      locationIds: $locationIds
      recurring: $recurring
    ) {
      totalPrevious12Months {
        month
        monthlyChurn
      }
      locationDetail {
        state
        shortName
        name
      }
    }
  }
`;

export const GET_MEMBERSHIPS_BY_LOCATION_ID = gql`
  query getMembershipByLocationId(
    $locationId: ID!
    $memberId: ID
    $operationName: String!
  ) {
    getMembershipByLocationId(
      locationId: $locationId
      memberId: $memberId
      operationName: $operationName
    ) {
      id
      description
      membershipName
      recurring
      membershipLevel
      membershipType
      paymentDelay {
        type
        amount
      }
      brandDetails {
        id
        name
      }
      activationDates {
        items {
          id
          startDateTime
          endDateTime
          isActive
        }
      }
      contractLength {
        amount
        type
      }
      paymentFrequency {
        amount
        type
      }
      membershipLocations(
        limit: 1000
        filter: { locationId: { eq: $locationId } }
      ) {
        items {
          id
          costPrice
          membershipId
          locationId
        }
      }
    }
  }
`;

export const GET_ALL_LOCATIONS_BY_LOCATIONID = gql`
  query getAllMembershipByLocation(
    $locationId: ID!
    $limit: Int
    $nextToken: String
  ) {
    getMembershipByLocationId(
      locationId: $locationId
      operationName: "getAllMembershipByLocationId"
    ) {
      membershipLocations(
        filter: { locationId: { eq: $locationId } }
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          costPrice
          joiningFee
          activationFee
          fobFee
          isActive
          membershipDetails {
            membershipType
            id
            isActive
            isLiveOnWebsite
            joiningFee
            joiningFee2
            joiningFee3
            membershipName
            description
            recurring
            membershipLevel
            activationDates(filter: { isActive: { eq: true } }) {
              items {
                createdAt
                endDateTime
                id
                isActive
                membershipId
                startDateTime
                updatedAt
                __typename
              }
              __typename
            }
            contractLength {
              amount
              type
            }
            paymentFrequency {
              type
              amount
            }
            paymentDelay {
              amount
              type
            }
            brandDetails {
              name
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const getCreditBilling = gql`
  query listBillingCredits(
    $filter: ModelBillingCreditFilterInput
    $limit: Int
  ) {
    listBillingCredits(filter: $filter, limit: $limit) {
      items {
        amount
        createdAt
        creditType
        currency
        id
        isProcessed
        locationId
        memberContractBillingId
        memberContractId
        memberId
        type
        updatedAt
      }
    }
  }
`;
export const GET_ALL_HEALTHQUESTIONNAIRE = gql`
  query listHealthQuestionnaires(
    $filter: ModelHealthQuestionnaireFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHealthQuestionnaires(
      filter: $filter
      nextToken: $nextToken
      limit: $limit
    ) {
      items {
        createdAt
        brandId
        brandDetails {
          name
        }
        expectedAnswer
        id
        isActive
        isDeleted
        question
        mandatory
        answerType
        sectionId
        sectionLabel
      }
      nextToken
    }
  }
`;

export const GET_HEALTHQUESTIONNAIRE_BY_ID = gql`
  query getHealthQuestionnaire($id: ID!) {
    getHealthQuestionnaire(id: $id) {
      isDeleted
      answerType
      brandId
      createdAt
      createdBy
      expectedAnswer
      id
      isActive
      mandatory
      question
      sectionId
      sectionLabel
      updatedAt
    }
  }
`;

export const GET_CHANGE_MEMBERSHIP_DETAILS = gql`
  query getChangeMembershipDetails(
    $memberId: ID!
    $oldMemberContractId: ID!
    $newMembershipId: ID!
    $membershipLocationId: ID!
  ) {
    getChangeMembershipDetails(
      memberId: $memberId
      oldMemberContractId: $oldMemberContractId
      newMembershipId: $newMembershipId
      membershipLocationId: $membershipLocationId
      getInformation: true
    ) {
      prorata
      downgradeFee
      debitCost
      upfrontPaymentAmount
      firstDDDate
    }
  }
`;

export const GET_MEMBER_CONTRACT_SUSPENSION_BY_LOCATIONID = gql`
  query getMemberContractSuspensionByLocationId(
    $locationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMemberContractSuspensionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMemberContractSuspensionByLocationId(
      locationId: $locationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberId
        memberDetails {
          givenName
          surname
        }
        contractDetails {
          timezone
        }
        createdAt
        suspensionStartDateTime
        suspensionEndDateTime
        suspensionStatus
        cancelledDateTime
      }
      nextToken
    }
  }
`;
export const MEDICAL_SUSPENSIONS = gql`
  query listMemberContractSuspensions(
    $filter: ModelMemberContractSuspensionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberContractSuspensions(
      limit: $limit
      nextToken: $nextToken
      filter: $filter # { #  suspensionType: { eq: MEDICAL } #  suspensionStatus: { eq: PENDING } #}
    ) {
      items {
        cancelledDateTime
        createdAt
        id
        imageUrl
        locationId
        memberContractId
        memberId
        suspensionCost
        suspensionEndDateTime
        suspensionReason
        suspensionStartDateTime
        suspensionStatus
        suspensionType
        updatedAt
        memberDetails {
          givenName
          surname
        }
      }
      nextToken
    }
  }
`;
export const MEDICAL_SUSPENSIONS_BY_ID = gql`
  query getMemberContractSuspension($id: ID!) {
    getMemberContractSuspension(id: $id) {
      cancelledDateTime
      createdAt
      id
      imageUrl
      locationId
      memberContractId
      memberDetails {
        givenName
        surname
      }
      memberId
      suspensionCost
      suspensionEndDateTime
      suspensionReason
      suspensionStartDateTime
      suspensionStatus
      suspensionType
      updatedAt
    }
  }
`;

export const LIST_TRANSACTION_BY_MEMBERID = gql`
  query getTransactionByMemberId($memberId: ID!) {
    getTransactionByMemberId(memberId: $memberId) {
      nextToken
      items {
        amount
        paymentType
        billingCreditId
        createdAt
        currency
        id
        refundTransactionId
        orderId
        locationId
        memberContractId
        memberContractSuspensionId
        memberId
        membershipId
        message
        paymentGatewayLogId
        status
        transactionId
        type
        gatewayCode
        memberContractDetails {
          id
          memberId
          membershipId
          voucherDetail {
            id
            voucherCode
            startDateTime
            endDateTime
          }
        }
      }
    }
  }
`;

export const LIST_MEMBER_REPORT_CONTRACTS = gql`
  query listMemberContracts(
    $filter: ModelMemberContractFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMemberContracts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        memberDetails {
          brandDetails {
            name
          }
          contracts {
            items {
              expiryDateTime
              startDateTime
              membershipName
              endDateTime
              paymentFrequency {
                type
                amount
              }
              billings {
                items {
                  refundTransactionId
                  debitDate
                  debitAmount
                  isProcessed
                }
              }
              suspensions {
                items {
                  suspensionStartDateTime
                  suspensionEndDateTime
                }
              }
            }
          }
          memberId
          aliasMemberId
          email
          createdAt
          secondaryEmail
          title
          givenName
          middleName
          surname
          gender
          dob
          mobileNumber
          address
          suburb
          state
          postCode
          country

          homeLocationId
          joinLocationId
          type
          role
          isBlocked
          createdAt
          updatedAt
          joinLocationId
          memberBlockDetails {
            items {
              blockFrom
              blockTo
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const search_REPORT_CONTRACTS = gql`
  query searchMemberContracts(
    $filter: SearchableMemberContractFilterInput
    $limit: Int
    $sort: [SearchableMemberContractSortInput]
    $nextToken: String
  ) {
    searchMemberContracts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        id
        createdAt
        memberDetails {
          brandDetails {
            name
            id
          }
          contracts {
            items {
              expiryDateTime
              startDateTime
              membershipName
              endDateTime
              paymentFrequency {
                type
                amount
              }
              paymentDelay {
                amount
                type
              }
              billings {
                items {
                  debitDate
                  debitAmount
                  isProcessed
                }
              }
              voucherDetail {
                id
                cost
                passFee
                joiningFee
                activationFee
                paymentDelay {
                  amount
                  type
                }
              }
              suspensions {
                items {
                  suspensionStartDateTime
                  suspensionEndDateTime
                }
              }
              juniorMemberconsentFormDetail {
                id
                signature
                consentFormInviteTokenExpiry
              }
              isPending
              joiningFee
              joiningFee2
              joiningFee3
              costPrice
              memberDetails {
                paymentDetails {
                  items {
                    paymentType
                    primary
                  }
                }
              }
            }
          }
          paymentDetails {
            items {
              accountNumber
              accountName
              bsb
              cardCvv
              cardExpiryDate
              cardHolderName
              cardNumber
              cardType
              id
              paymentType
              primary
              createdAt
              cardToken
              vivaPaymentInformationId
            }
          }
          memberId
          aliasMemberId
          email
          createdAt
          secondaryEmail
          title
          givenName
          middleName
          surname
          gender
          dob
          mobileNumber
          address
          suburb
          state
          postCode
          country

          homeLocationId
          joinLocationId
          type
          role
          isBlocked
          createdAt
          updatedAt
          joinLocationId
          homeLocationDetails {
            name
          }
          memberBlockDetails {
            items {
              blockFrom
              blockTo
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const LIST_MEMBER_REPORT_ACTIVE = gql`
  query getMembersActive(
    $action: String!
    $locationId: String!
    $sort: String!
  ) {
    getMembersActive(action: $action, locationId: $locationId, sort: $sort) {
      data {
        memberId
        createdAt
        isActive
        isLoginEnabled
        aliasMemberId
        email
        secondaryEmail
        title
        givenName
        middleName
        surname
        gender
        dob
        mobileNumber
        imageUrl
        address
        suburb
        state
        postCode
        country

        joinedDateTime
        homeLocationId
        joinLocationId
        getLinksSms
        accepted
        emergencyContactName
        emergencyContactRelation
        emergencyContactEmail
        emergencyContactNumber
        emergencyContactName2
        emergencyContactRelation2
        emergencyContactEmail2
        emergencyContactNumber2
      }
    }
  }
`;
export const LIST_MEMBER_BLOCKED = gql`
  query listMemberBlocks($filter: ModelMemberBlockFilterInput) {
    listMemberBlocks(filter: $filter) {
      items {
        createdAt
        memberId
        aliasMemberId
        email
        createdAt
        secondaryEmail
        title
        givenName
        middleName
        surname
        gender
        dob
        mobileNumber
        address
        suburb
        state
        postCode
        country
        joinedDateTime
        homeLocationId
        joinLocationId
        type
        role
        outstandingBalance
        isBlocked
        createdAt
        updatedAt
        joinLocationId
        brandDetails {
          name
        }
        contracts(filter: $contractFilter) {
          items {
            isActive
            expiryDateTime
            startDateTime
            membershipName
            endDateTime
            paymentFrequency {
              type
              amount
            }
            billings {
              items {
                # debitDate
                debitAmount
                isProcessed
                BillingCredit {
                  items {
                    amount
                  }
                }
              }
            }
            suspensions {
              items {
                suspensionStartDateTime
                suspensionEndDateTime
                cancelledDateTime
                suspensionStatus
                suspensionType
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const LIST_MEMBER_REPORT_JOINED = gql`
  query listMembers($filter: ModelMemberFilterInput) {
    listMembers(filter: $filter) {
      items {
        memberId
      }
    }
  }
`;
export const LIST_STATISTICS = gql`
  query listStatistics($filter: ModelStatisticsFilterInput) {
    listStatistics(filter: $filter) {
      items {
        locationId
        totalMember
        totalLeaver
        totalJoiner
      }
    }
  }
`;
export const LIST_VOUCHERS = gql`
  query listVouchers(
    $filter: ModelVoucherFilterInput!
    $nextToken: String
    $limit: Int
  ) {
    listVouchers(filter: $filter, nextToken: $nextToken, limit: $limit) {
      items {
        id
        membershipId
        voucherCode
        startDateTime
        endDateTime
        quantity
        visit
        used
        type
        cost
        isActive
        note
        passFee
        activationFee
        createdAt
        createdBy
        updatedAt
        membershipDetail {
          id
          membershipName
          membershipType
          costPrice
        }
        voucherMembers {
          items {
            id
            voucherId
            memberDetail {
              memberId
            }
            membershipId
          }
        }
        voucherLocation {
          items {
            id
            locationId
            voucherId
          }
        }
        memberType
      }
      nextToken
    }
  }
`;

export const GET_VOUCHER_LOCATION_BY_LOCATIONID = gql`
  query getVoucherLocationByLocationId(
    $locationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVoucherLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getVoucherLocationByLocationId(
      locationId: $locationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        voucherId
        locationId
        updatedAt
        createdAt
        voucherDetails {
          voucherCode
          quantity
          type
          used
          membershipDetail {
            membershipName
          }
        }
      }
      nextToken
    }
  }
`;
// export const GET_VOUCHER_BY_CODE = gql`
//   query getVoucherByVoucherCode($voucherCode: String!) {
//     getVoucherByVoucherCode(voucherCode: $voucherCode) {
//       items {
//         id
//         membershipId
//         voucherCode
//         startDateTime
//         endDateTime
//         quantity
//         visit
//         used
//         type
//         cost
//         isActive
//         note
//         passFee
//         activationFee
//         createdAt
//         createdBy
//         updatedAt
//       }
//     }
//   }
// `;
export const GET_VOUCHER_BY_ID = gql`
  query getVoucher($id: ID!) {
    getVoucher(id: $id) {
      id
      membershipId
      voucherCode
      startDateTime
      endDateTime
      quantity
      visit
      used
      type
      cost
      isActive
      note
      passFee
      activationFee
      joiningFee
      createdBy
      locationId
      createdAt
      updatedAt
      duration
      memberType
      paymentDelay {
        amount
        type
      }
      locationDetail {
        id
        name
      }
      createdByDetail {
        memberId
        givenName
        surname
      }
      membershipDetail {
        id
        membershipName
        membershipType
        costPrice
        membershipLocations {
          items {
            id
            costPrice
            joiningFee
            activationFee
            fobFee
            locationId
          }
        }
        contractLength {
          amount
          type
        }
        paymentFrequency {
          type
          amount
        }
      }
      voucherMembers {
        items {
          id
          voucherId
          isRedeemed
          memberDetail {
            memberId
            givenName
            surname
            createdAt
          }
        }
      }
      voucherLocation {
        items {
          id
          createdAt
          locationDetail {
            name

            createdAt
          }
        }
      }
    }
  }
`;
export const GET_VOUCHER_MEMBER = gql`
  query getVoucherMemberByVoucherId($voucherId: ID!) {
    getVoucherMemberByVoucherId(voucherId: $voucherId) {
      items {
        id
        voucherId
        memberId
        membershipId
        isRedeemed
        createdAt
        updatedAt
        memberDetail {
          memberId
          surname
          middleName
          givenName
        }
      }
    }
  }
`;
export const GET_VOUCHER_LOCATION = gql`
  query getVoucherLocationByVoucherId($voucherId: ID!) {
    getVoucherLocationByVoucherId(voucherId: $voucherId) {
      items {
        id
        voucherId
        locationId
        createdAt
        updatedAt
        locationDetail {
          id
          name
        }
      }
    }
  }
`;
export const LIST_MEMBER_CONTRACT_BILLINGS = gql`
  query listMemberContractBillings(
    $filter: ModelMemberContractBillingFilterInput
    $nextToken: String
  ) {
    listMemberContractBillings(
      filter: $filter
      nextToken: $nextToken
      limit: 500
    ) {
      items {
        id
        memberId
        isProcessed
        debitDate
        debitAmount
        status
        memberDetails {
          memberId
          aliasMemberId
          givenName
          middleName
          surname
          email
          mobileNumber
          brandId
          brandDetails {
            name
            id
          }
        }
        memberContractDetails {
          memberId
          contractLength {
            amount
            type
          }
          paymentFrequency {
            amount
            type
          }
          recurring
          endDateTime
          endDate
          expiryDate
          expiryDateTime
        }
      }
      nextToken
    }
  }
`;
export const LIST_TRANSACTIONS = gql`
  query listTransactions(
    $filter: ModelTransactionFilterInput
    $nextToken: String
  ) {
    listTransactions(filter: $filter, nextToken: $nextToken, limit: 500) {
      items {
        id
        memberId
        transactionId
        orderId
        debitDate
        amount
        type
        memberContractDetails {
          memberId
          contractLength {
            amount
            type
          }
          paymentFrequency {
            amount
            type
          }
          recurring
          memberDetails {
            memberId
            aliasMemberId
            givenName
            middleName
            surname
            email
            mobileNumber
            brandId
            brandDetails {
              name
              id
            }
          }
        }
        memberDetails {
          memberId
          aliasMemberId
          givenName
          middleName
          surname
          email
          mobileNumber
          brandId
          brandDetails {
            name
            id
          }
        }
      }
      nextToken
    }
  }
`;
export const LIST_REJECTIONS_PAYMENT = gql`
  query listRejectionPayments(
    $filter: ModelRejectionPaymentFilterInput
    $nextToken: String
  ) {
    listRejectionPayments(filter: $filter, nextToken: $nextToken, limit: 500) {
      items {
        id
        memberId
        debitDate
        brandShortName
        amount
        memberDetails {
          memberId
          aliasMemberId
          givenName
          middleName
          surname
          email
          mobileNumber
          brandId
          brandDetails {
            name
            id
          }
        }
      }
      nextToken
    }
  }
`;
export const MEMBER_VISITS_REPORT = gql`
  query listDoorAccessLogs(
    $limit: Int
    $filter: ModelDoorAccessLogFilterInput
  ) {
    listDoorAccessLogs(limit: $limit, filter: $filter) {
      items {
        createdAt
        id
        isValid
        locationId
        locationState
        swipePhoto
        memberPhoto
        memberHomeClub
        memberId
        updatedAt
        doorDetails {
          id
          doorName
          accessPointname
          accessPoint
        }
        locationDetails {
          id
          name
        }
        memberContract {
          costPrice
          isActive
          recurring
          paymentFrequency {
            amount
            type
          }
          startDateTime
          billings {
            items {
              isProcessed
              debitAmount
            }
          }
          memberDetails {
            surname
            givenName
            email
            mobileNumber
            aliasMemberId
            type
            createdAt
          }
        }
        deviceType
        memberContractId
        memberName
      }
      nextToken
    }
  }
`;
export const GET_LIST_FEATURES = gql`
  query listFeatures {
    listFeatures {
      items {
        id
        name
        description
        imageUrl
        createdAt
      }
    }
  }
`;
export const REFUNDS_IN_LOCATION = gql`
  query getRefundTransactionsByLocationId(
    $locationId: ID!
    $nextToken: String
    $limit: Int
  ) {
    getRefundTransactionsByLocationId(
      locationId: $locationId
      nextToken: $nextToken
      sortDirection: DESC
      limit: $limit
    ) {
      items {
        id
        memberId
        locationId
        brandId
        transactionId
        memberContractBillingId
        refundBy
        cardNumber
        batchId
        batchPaymentId
        refundAmount
        refundDate
        refundType
        refundStatus
        refundReason
        createdAt
        updatedAt
        memberDetails {
          surname
          givenName
          email
        }
        refundByDetails {
          surname
          givenName
        }
        requestByDetails {
          surname
          givenName
        }
      }
      nextToken
    }
  }
`;
export const healthQuestionsByBrandId = gql`
  query getHealthQuestionnairesByBrandId {
    getHealthQuestionnairesByBrandId(
      brandId: "6dec4e5f-7a07-4a7e-a809-2c0c1df01366"
      filter: { isDeleted: { eq: false }, isActive: { eq: true } }
    ) {
      items {
        createdAt
        brandId
        expectedAnswer
        id
        isActive
        isDeleted
        question
        mandatory
        answerType
        sectionId
        sectionLabel
      }
      nextToken
    }
  }
`;
export const GET_VOUCHER_BY_CODE = gql`
  query getVoucherByVoucherCode($voucherCode: String!) {
    getVoucherByVoucherCode(voucherCode: $voucherCode) {
      items {
        id
        membershipId
        voucherCode
        startDateTime
        endDateTime
        quantity
        visit
        used
        type
        cost
        isActive
        note
        passFee
        activationFee
        joiningFee
        createdAt
        createdBy
        updatedAt
        duration
        paymentDelay {
          amount
          type
        }
        membershipDetail {
          id
          membershipName
          recurring
          costPrice
          joiningFee
          joiningFee2
          joiningFee3
          description
          contractLength {
            amount
            type
          }
          paymentFrequency {
            type
            amount
          }
          membershipLocations {
            items {
              id
              locationId
            }
          }
        }
        voucherMembers {
          items {
            voucherId
            memberId
          }
        }
        voucherInvitations {
          items {
            memberId
            memberDetails {
              memberId
              givenName
              surname
            }
          }
        }
        voucherLocation {
          items {
            id
            locationId
            locationDetail {
              id
              name
            }
          }
        }
        memberType
      }
    }
  }
`;
export const GET_MEMBER_RECEIPT = gql`
  query getMemberReceiptByMemberId(
    $memberId: ID!
    $filter: ModelMemberReceiptFilterInput
  ) {
    getMemberReceiptByMemberId(memberId: $memberId, filter: $filter) {
      items {
        transactionId
        debitDate
        pdfUrl
        amount
      }
    }
  }
`;

export const GET_SMS_OUTBOX_BY_LOCATIONID = gql`
  query getSmsOutboxByLocationId(
    $locationId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSmsOutboxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSmsOutboxByLocationId(
      locationId: $locationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        to
        message
        from
        locationId
        brandId
        memberId
        identityName
        outGoingId
        status
        sentDateTime
        messageLength
        totalMessage
        createdAt
        updatedAt
        memberDetails {
          givenName
          surname
          email
        }
      }
      nextToken
    }
  }
`;

export const GET_SMS_INCOMING_BY_DESTINATION = gql`
  query getSmsIncomingByDestination(
    $to: String!
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSmsIncomingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSmsIncomingByDestination(
      to: $to
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        messageId
        from
        to
        memberId
        locationId
        message
        dateTime
        provider
        createdAt
        updatedAt
        memberDetails {
          email
          surname
          givenName
          mobileNumber
        }
      }
      nextToken
    }
  }
`;
export const GET_REFUND_TRANSACTION_BY_BRAND = gql`
  query getRefundTransactionsByByBrandIdAndFilter(
    $filter: String
    $operationName: String!
    $brandId: String!
  ) {
    getRefundTransactionsByByBrandIdAndFilter(
      filter: $filter
      operationName: $operationName
      brandId: $brandId
    ) {
      id
      refundStatus
      paymentType
      refundDate
      refundAmount
      createdAt
      accountNumber
      bsb
      memberDetails {
        aliasMemberId
        givenName
        surname
        memberId
      }
      billingDetail {
        debitDate
      }
      requestByDetails {
        surname
        givenName
      }
      refundByDetails {
        surname
        givenName
      }
      locationDetails {
        name
      }
      updatedAt
    }
  }
`;
export const GET_NOTIFCATION_BY_ID = gql`
  query getNotification($id: ID!) {
    getNotification(id: $id) {
      eventType
      email
      suspensionStart
      suspensionType
      displayUntil
      outstandingBalance
      cancelDate
      memberId
      contractId
      createdAt
      locationId
      brandId
      actionByMemberId
      memberDetails {
        memberId
        givenName
        surname
        outstandingBalance
      }
      actionByMemberDetails {
        givenName
        surname
      }
      locationDetails {
        name
      }
      brandDetails {
        name
      }
    }
  }
`;
export const GET_SETTLEMENT_REPORT_BY_LOCATION_ID = gql`
  query getSettlementReportByLocationId(
    $locationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSettlementReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSettlementReportByLocationId(
      locationId: $locationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
      }
      nextToken
    }
  }
`;

export const GET_SETTLEMENT_REPORT_BY_BRAND_ID = gql`
  query getSettlementReportByBrandId(
    $brandId: ID!
    $filter: ModelSettlementReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSettlementReportByBrandId(
      brandId: $brandId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        locationName
        locationDetails {
          settlementEmail
        }
      }
      nextToken
    }
  }
`;
export const LIST_MAINTENANCE_DATES = gql`
  query listLocationMaintenances(
    $limit: Int
    $filter: ModelLocationMaintenanceFilterInput
    $nextToken: String
  ) {
    listLocationMaintenances(
      limit: $limit
      filter: $filter
      nextToken: $nextToken
    ) {
      items {
        id
        isDeleted
        locationId
        startDateTime
        endDateTime
        createdBy
        createdAt
      }
      nextToken
    }
  }
`;
