import "./specificLocation.scss";
import React, { useEffect, useRef, useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import googlemaps from "../../assets/images/icons/maps_icons.png";
import twentyfour from "../../assets/images/icons/twentyfourblue.png";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { useLocationContext } from "../../contexts/LocationContext";
import { useMemberContext } from "../../contexts/MemberContext";
import { useHandleError } from '../../hooks/useHandleError';
import {
  CREATE_LOCATION_AUDIT_OPERATION,
  DEBT_COLLECTION_ACTIONS,
  UPDATE_LOCATION,
} from "../../gqloperations/mutations";
import {
  GET_MEMBER_BY_TYPE_FOR_LOCATION,
  LIST_LOCATION,
} from "../../gqloperations/queries";
import {
  Header,
  BankDetails,
  ClubSummary,
  ClubImage,
  ClubFeatures,
  Studio,
  MembershipLocation,
  LocationRefunds,
  LocationHours,
  DoorAccess,
  Sales,
  ManagementStaff,
  ClubSettings,
  VoucherLocation,
  SMSLocation,
  Maintenance,
  DebtCollection,
} from "../../components";
import SettlementReport from "../../components/location/singleLocation/settlement_report_tab/SettlementReport";

const SpecificLocation = () => {
  let { id } = useParams();
  const handleError = useHandleError();
  const listRef = useRef(null);
  const { oldValues, sidebarBrandId } = useMemberContext();
  const { createLog: createLogUserInteractivity } = useMemberContext();
  const { loggedUser, permissions } = useAuthContext();
  const [oldAttribute, setOldAttribute] = useState({});
  const { state, dispatch, getLocationById } = useLocationContext();

  const [getListMembersByType, { error: memberError, loading: memberLoading }] =
    useLazyQuery(GET_MEMBER_BY_TYPE_FOR_LOCATION);

  const [memberData, setMemberData] = useState([]);

  const [auditOperation] = useMutation(CREATE_LOCATION_AUDIT_OPERATION);
  const [updateLocation] = useMutation(UPDATE_LOCATION);
  const [deptCollectionActions] = useMutation(DEBT_COLLECTION_ACTIONS);

  useEffect(() => {
    createLogUserInteractivity("Clicked location icon", "location module");
  }, []);
  useEffect(() => {
    if (id) {
      dispatch({ type: "SET_LOCATION_ID", payload: id });
    }
  }, [id, dispatch]);

  const fetchMembers = async (nextToken) => {
    try {
      const { data } = await getListMembersByType({
        fetchPolicy: "no-cache",
        variables: { nextToken: nextToken },
      });
      return {
        items: data.getMemberByType.items,
        nextToken: data.getMemberByType?.nextToken,
      };
    } catch (error) {
      console.error("Error fetching members: ", error);
      return {
        items: [],
        nextToken: null,
      };
    }
  };
  const fetchAllMembers = async () => {
    let allItems = [];
    let token = null;

    do {
      const { items, nextToken } = await fetchMembers(token);

      allItems = [...allItems, ...items];
      token = nextToken;
    } while (token);

    return { getMemberByType: { items: allItems } };
  };
  useEffect(() => {
    const loadMembers = async () => {
      const membersData = await fetchAllMembers();
      setMemberData(membersData);
    };

    loadMembers();
  }, []);

  const createLog = async (values) => {
    delete values.id;
    const newAttributeName = Object.keys(values);
    const newAttributeValue = Object.values(values);
    const oldAttributeName = Object.keys(oldAttribute);
    const oldAttributeValue = Object.values(oldAttribute);
    const auditLog = {
      newAttributeValue: newAttributeValue,
      newAttributeName: newAttributeName,
      oldAttributeValue: oldAttributeValue,
      oldAttributeName: oldAttributeName,
      locationId: `${state.id}`,
      updatedBy: loggedUser?.getMember?.memberId,
    };
    try {
      await auditOperation({
        variables: {
          input: auditLog,
        },
        onCompleted: () => {
          getLocationById();
        },
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };

  const updateDebtCollectionType = async (debtType) => {
    await deptCollectionActions({
      variables: {
        input: {
          brandId: sidebarBrandId,
          locationId: id,
          action: "UPDATE_DEBT_COLLECTION_TYPE",
          debtType: debtType,
        },
      },
    });
  };

  const onSubmit = async (values, OnSubmitProps) => {
    let differences = {};
    function getObjectDifferences(obj1, obj2) {
      for (let key in obj1) {
        if (obj1[key] !== obj2[key]) {
          differences[key] = obj1[key];
          if (differences[key].length === 0) {
            differences[key] = null;
          }
        }
      }

      return differences;
    }

    getObjectDifferences(values, oldValues);
    try {
      if (
        state.location.getLocation.state === "NZ" &&
        differences?.bsb === null
      ) {
        differences.bsb = "000000";
      }
      if (differences?.openDate) {
        differences.openDate = new Date(differences.openDate).toISOString();
      }
      await updateLocation({
        variables: {
          input: differences,
        },

        onCompleted: () => {
          OnSubmitProps.resetForm();
          createLog(values);
          toast.success("Updated successfully");
          getLocationById();
          updateDebtCollectionType(differences.debtCollectionType);
        },
        refetchQueries: [
          {
            query: LIST_LOCATION,
            fetchPolicy: "no-cache",
          },
        ],
      });
    } catch (error) {
      handleError(`${error}`);
      OnSubmitProps.resetForm();
    }
  };

  const onSubmitWithoutFormik = async (values) => {
    try {
      await updateLocation({
        variables: {
          input: values,
        },

        onCompleted: () => {
          createLog(values);
          toast.success("Updated successfully");
          getLocationById();
        },
        refetchQueries: [
          {
            query: LIST_LOCATION,
            fetchPolicy: "no-cache",
          },
        ],
      });
    } catch (error) {
      handleError(`${error}`);
    }
  };

  const handleScrollLeft = () => {
    listRef.current.scrollLeft -= 100;
  };

  const handleScrollRight = () => {
    listRef.current.scrollLeft += 100;
  };

  // if (memberLoading)
  //   return (
  //     <div className="dashboard-loading">
  //       <BeatLoader color="white" />
  //     </div>
  //   );
  if (memberError) {
    return (
      <div>
        <div>Problem in getting location</div>
      </div>
    );
  }

  return (
    <>
      <Header pageTitle="Locations" />
      <div className="main-location-container mt-12">
        <div className="location-details-header">
          <div className="title-container">
            <div className="addition-info-container  mb-24">
              <div className="loc-info">
                {/* <img src={locPin} alt="loc" /> */}
                <FaMapMarkerAlt />
                <p className="fs-12">
                  {state?.location?.getLocation?.address1}{" "}
                  {state?.location?.getLocation?.suburb}{" "}
                  {state?.state?.getLocation?.postCode}{" "}
                  {state?.location?.getLocation?.city}{" "}
                  {state?.location?.getLocation?.country}
                </p>
              </div>
              <div className="contact-info">
                <p className="fs-12">
                  Tel: {state?.location?.getLocation?.phoneNumber}
                </p>
                <p className="fs-12">
                  Email: {state?.location?.getLocation?.email}
                </p>
              </div>
            </div>
            <div className="info-icons">
              <img src={twentyfour} alt="24 hours" />
              <img src={googlemaps} alt="google map" />
            </div>
          </div>
        </div>
        <div className="location-content-container">
          <div className="tabs">
            <div className="tabs-wrapper">
              {/* {scrollPosition > 0 && ( */}
              <div onClick={handleScrollLeft} className="prev-icon">
                <IoIosArrowDropleft />
              </div>
              {/* )} */}
              <div ref={listRef} className="tabs-list">
                <div
                  className={
                    state.index !== 0
                      ? "tab-head fs-12 back-mediumgray"
                      : "tab-head fs-12 back-persianblue"
                  }
                  onClick={() => dispatch({ type: "SET_INDEX", payload: 0 })}
                >
                  Details
                </div>

                <div
                  className={
                    state.index !== 1
                      ? "tab-head fs-12 back-mediumgray"
                      : "tab-head fs-12 back-persianblue"
                  }
                  onClick={() => dispatch({ type: "SET_INDEX", payload: 1 })}
                >
                  Hours
                </div>

                <div
                  className={
                    state.index !== 2
                      ? "tab-head fs-12 back-mediumgray"
                      : "tab-head fs-12 back-persianblue"
                  }
                  onClick={() => dispatch({ type: "SET_INDEX", payload: 2 })}
                >
                  Website
                </div>
                <div
                  className={
                    state.index !== 3
                      ? "tab-head fs-12 back-mediumgray"
                      : "tab-head fs-12 back-persianblue"
                  }
                  onClick={() => dispatch({ type: "SET_INDEX", payload: 3 })}
                >
                  Studio
                </div>

                {permissions.includes("CanViewLocationDoorAccess") && (
                  <div
                    className={
                      state.index !== 4
                        ? "tab-head fs-12 back-mediumgray"
                        : "tab-head fs-12 back-persianblue"
                    }
                    onClick={() => dispatch({ type: "SET_INDEX", payload: 4 })}
                  >
                    Access
                  </div>
                )}
                <div
                  className={
                    state.index !== 5
                      ? "tab-head fs-12 back-mediumgray"
                      : "tab-head fs-12 back-persianblue"
                  }
                  onClick={() => dispatch({ type: "SET_INDEX", payload: 5 })}
                >
                  Membership
                </div>
                <div
                  className={
                    state.index !== 6
                      ? "tab-head fs-12 back-mediumgray"
                      : "tab-head fs-12 back-persianblue"
                  }
                  onClick={() => dispatch({ type: "SET_INDEX", payload: 6 })}
                >
                  Refund
                </div>
                <div
                  className={
                    state.index !== 7
                      ? "tab-head fs-12 back-mediumgray"
                      : "tab-head fs-12 back-persianblue"
                  }
                  onClick={() => dispatch({ type: "SET_INDEX", payload: 7 })}
                >
                  Voucher
                </div>
                <div
                  className={
                    state.index !== 8
                      ? "tab-head fs-12 back-mediumgray"
                      : "tab-head fs-12 back-persianblue"
                  }
                  onClick={() => dispatch({ type: "SET_INDEX", payload: 8 })}
                >
                  SMS
                </div>
                {state.location?.getLocation?.debtCollectionType &&
                  state.location?.getLocation?.debtCollectionType !==
                    "NONE" && (
                    <div
                      className={
                        state.index !== 9
                          ? "tab-head fs-12 back-mediumgray"
                          : "tab-head fs-12 back-persianblue"
                      }
                      onClick={() =>
                        dispatch({ type: "SET_INDEX", payload: 9 })
                      }
                    >
                      Debt Collection
                    </div>
                  )}

                <div
                  className={
                    state.index !== 10
                      ? "tab-head fs-12 back-mediumgray"
                      : "tab-head fs-12 back-persianblue"
                  }
                  onClick={() => dispatch({ type: "SET_INDEX", payload: 10 })}
                >
                  SETTLEMENT
                </div>
                {/* <div
                  className={
                    state.index !== 11
                      ? "tab-head fs-12 back-mediumgray"
                      : "tab-head fs-12 back-persianblue"
                  }
                  onClick={() => dispatch({ type: "SET_INDEX", payload: 11 })}
                >
                  MAINTENANCE DATES
                </div> */}
                {/* <div
                  className={
                    state.index !== 6
                      ? "tab-head fs-12 back-mediumgray"
                      : "tab-head fs-12 back-persianblue"
                  }
                  onClick={() => dispatch({ type: "SET_INDEX", payload: 6 })}
                >
                  Pin Codes
                </div>
                <div
                  className={
                    state.index !== 7
                      ? "tab-head fs-12 back-mediumgray"
                      : "tab-head fs-12 back-persianblue"
                  }
                  onClick={() => dispatch({ type: "SET_INDEX", payload: 7 })}
                >
                  Alarms
                </div> */}
              </div>
              <div onClick={handleScrollRight} className="next-icon">
                <IoIosArrowDropright />
              </div>
            </div>

            {/*  CLUB INFO TAB CONTENT */}
            <div className="tabContent " hidden={state.index !== 0}>
              <div className="club-info-container">
                <div className="first-card pd-12">
                  <div className="title-bar pb-12">
                    {memberData && !memberLoading && !memberError ? (
                      <ManagementStaff
                        memberdata={memberData}
                        onSubmit={onSubmit}
                        oldAttribute={oldAttribute}
                        setOldAttribute={setOldAttribute}
                        state={state}
                      />
                    ) : (
                      <div className="loading-center">
                        <div className="fs-10">Loading staff</div>
                        <BeatLoader color="white" />
                      </div>
                    )}
                  </div>
                </div>

                <ClubSettings
                  onSubmit={onSubmit}
                  oldAttribute={oldAttribute}
                  setOldAttribute={setOldAttribute}
                  state={state}
                />
                <BankDetails
                  onSubmit={onSubmit}
                  oldAttribute={oldAttribute}
                  setOldAttribute={setOldAttribute}
                  state={state}
                />

                {/* SALES CARD */}
                <Sales
                  onSubmit={onSubmit}
                  oldAttribute={oldAttribute}
                  setOldAttribute={setOldAttribute}
                  state={state}
                />
                {/* <Leads
                  memberdata={memberData}
                  onSubmit={onSubmit}
                  oldAttribute={oldAttribute}
                  setOldAttribute={setOldAttribute}
                  state={state}
                /> */}
                {/* LEADS CARD */}
              </div>
            </div>
            {/* STAFFED HOURS TAB CONTENT */}
            <div className="tabContent" hidden={state.index !== 1}>
              <LocationHours />
            </div>
            {/* WEBSITE TAB CONTENT */}
            <div className="tabContent" hidden={state.index !== 2}>
              <div className="website">
                <div className="first-row flex-wrap">
                  <ClubSummary
                    locationId={id}
                    summaryText={state?.location?.getLocation?.summary ?? ""}
                    getLocationById={getLocationById}
                  />
                  <ClubImage
                    locationId={id}
                    state={state}
                    onSubmit={onSubmitWithoutFormik}
                  />
                </div>
                {/* state?.location?.getLocation?.locationFeatures?.items.length >
                    0 && */}
                {permissions.includes("CanEditClubFeatures") && (
                  <div className="sec-row">
                    {state.index === 2 && <ClubFeatures locationId={id} />}
                  </div>
                )}
                {/* <FeatureTileGrid /> */}
              </div>
            </div>

            {/* DOOR ACCESS TAB CONTENT */}
            <div className="tabContent" hidden={state.index !== 3}>
              <Studio />
            </div>
            {permissions.includes("CanViewLocationDoorAccess") && (
              <div className="tabContent" hidden={state.index !== 4}>
                <DoorAccess />
              </div>
            )}

            <div className="tabContent" hidden={state.index !== 5}>
              <MembershipLocation />
            </div>
            <div className="tabContent" hidden={state.index !== 6}>
              <LocationRefunds locationId={id} />
            </div>
            <div className="tabContent" hidden={state.index !== 7}>
              <VoucherLocation locationId={id} />
            </div>
            <div className="tabContent" hidden={state.index !== 8}>
              <SMSLocation locationId={id} />
            </div>

            <div className="tabContent" hidden={state.index !== 9}>
              <DebtCollection locationId={id} state={state} />
            </div>
            <div className="tabContent" hidden={state.index !== 10}>
              <SettlementReport
                locationId={id}
                locationDetails={state?.location?.getLocation}
              />
            </div>
            <div className="tabContent" hidden={state.index !== 11}>
              <Maintenance locationId={id} />
            </div>
            {/* PIN CODES TAB CONTENT */}
            {/* <div className="tabContent" hidden={state.index !== 6}>
              <PinCodePage />
            </div> */}
            {/* ALARMS TAB CONTENT */}
            {/* <div className="tabContent" hidden={state.index !== 7}>
              TEST
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecificLocation;
